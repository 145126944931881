import ReactDOM from 'react-dom';
import React from 'react';
import { STYLES } from '../components/Styles';
import { updateSessionObject, appendStyleSheet, loadStyles, getFormattedCss } from './Utils';
import debounce from 'lodash/debounce';
export default (editor) => {
  const domc = editor.Components;
  const defType = domc.getType('default');
  const defModel = defType.model;
  const wrpChld = 'data-chld';
  var lastExecutedAt = 0;
  var runningUpdateStack = [];
  var stackTimeInterval = [];
  localStorage.setItem( "traitIds", JSON.stringify([]) );

  // Main React component
  domc.addType('react-component', {
    model: {
      toHTML(opts = {}) {
        return defModel.prototype.toHTML.call(this, {
          ...opts,
          tag: this.get('type')
        });
      }
    },
    view: {
      tagName: 'div',

      init() {
        const { model } = this;
        this.listenTo(model, 'add remove', this.hanldeComponentsChange);
        // this.listenTo(model, 'change:attributes', this.render);
        // this.listenTo(model, 'change:style ', this.updateWidth);
        // this.listenTo(model.components(), 'add remove reset', this.__upRender);
        // this.listenTo('trait:update', this.handleTraitsUpdate(model))
      },

      hanldeComponentsChange() {
        try {
          const componentName = this.model.attributes.component.name;
          let iframes = document.getElementsByClassName("gjs-frame");
          if (iframes) {
            let iframeHead = iframes[0].contentWindow.document.head;
            if (iframeHead) {
              let existingStyles = iframeHead.getElementsByTagName("style");
              for (let eStyle of existingStyles) {
                if (eStyle.getAttribute("name") && eStyle.getAttribute("name") == componentName) {
                  eStyle.parentNode.removeChild(eStyle);
                  break;
                }
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      },

      updateWidth() {
        model.setStyle({
          width: parseFloat(selectedWidth, 10) / containerWidth * 100 + '%'
        })
        //...
      },

      getChildrenContainer() {
        const { childrenContainer } = this;
        if (childrenContainer) return childrenContainer;

        this.childrenContainer = document.createElement('childc');

        return this.childrenContainer;
      },

      /**
       * We need this container to understand if the React component is able
       * to render children
       */
      createReactChildWrap() {
        return React.createElement('span', { [wrpChld]: true });
      },

      createReactEl(cmp, props) {
        return React.createElement(cmp, props, this.createReactChildWrap());
      },

      mountReact(cmp, el) {
        //createRoot(el).render(cmp);
        ReactDOM.render(cmp, el);
      },

      traitUpdate(value) {
        const i_component = value.component;
        const trait = value.trait;
        const ElmToRerender = i_component.attributes.component;
        window.gpEditor.Panels.getButton("options", "publishBtn").set("active", false);
        window.gpEditor.Panels.getButton("options", "savebtn").set("active", false);

        if(trait.attributes.name=='isPreview'||trait.attributes.name.includes("isPreview")) {
          i_component.attributes.attributes.ENDPOINTS.forEach(t=>{t.preview=trait.attributes.value;});
        }

        if (ElmToRerender) {
          i_component.attributes.attributes[trait.attributes.name] = trait.attributes.value;
          editor.store();

          let ElmRender = <ElmToRerender {...i_component.attributes.attributes} />
          if (i_component.view)
            ReactDOM.render(ElmRender, i_component.view.el);
        }
      },

      render() {

        editor.DomComponents.getComponent().get('components').forEach( mod => {
          
          let classes = JSON.parse(JSON.stringify(mod.attributes.classes));

          if( classes.includes("non-removeable") ) {
            const toolbar = mod.get('toolbar') || [];
            const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-delete' );
            mod.set({removable: false});
            mod.set('toolbar', filteredToolbar);
          }
          mod.forEachChild( c => {
            let classes = JSON.parse(JSON.stringify(c.attributes.classes));
            // console.log("classesclasses22", classes);
            if( classes.includes("non-removeable") ) {
              const toolbar = c.get('toolbar') || [];
              const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-delete' );
              c.set({removable: false});
              c.set('toolbar', filteredToolbar);
            }
          } );

          if( classes.includes("non-moveable") ) {
            const toolbar = mod.get('toolbar') || [];
            const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-move' );
            mod.set({draggable: false});
            mod.set('toolbar', filteredToolbar);
          }
          mod.forEachChild( c => {
            let classes = JSON.parse(JSON.stringify(c.attributes.classes));
            if( classes.includes("non-moveable") ) {
              const toolbar = c.get('toolbar') || [];
              const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-move' );
              c.set({draggable: false});
              c.set('toolbar', filteredToolbar);
            }
          } );

          if( classes.includes("non-cloneable") ) {
            const toolbar = mod.get('toolbar') || [];
            const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-clone' );
            mod.set({copyable: false});
            mod.set('toolbar', filteredToolbar);
          }
          mod.forEachChild( c => {
            let classes = JSON.parse(JSON.stringify(c.attributes.classes));
            if( classes.includes("non-cloneable") ) {
              const toolbar = c.get('toolbar') || [];
              const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-clone' );
              c.set({copyable: false});
              c.set('toolbar', filteredToolbar);
            }
          } );

        } );

        editor.DomComponents.getComponent().get('components').forEach( mod => {
          let classes = JSON.parse(JSON.stringify(mod.attributes.classes));

          if( classes.includes("non-removeable") ) {
            const toolbar = mod.get('toolbar') || [];
            const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-delete' );
            mod.set({removable: false});
            mod.set('toolbar', filteredToolbar);
          }
          mod.forEachChild( c => {
            let classes = JSON.parse(JSON.stringify(c.attributes.classes));
            if( classes.includes("non-removeable") ) {
              const toolbar = c.get('toolbar') || [];
              const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-delete' );
              c.set({removable: false});
              c.set('toolbar', filteredToolbar);
            }
          } );
        } );

        this.model.traits.forEach(tr => {
          tr.setValue(this.model.attributes.attributes[tr.getName()]);
        });

        stackTimeInterval = [];
        this.em.on('trait:update', (value) => {
          let remoteTraitIds = localStorage.getItem("traitIds");
          if( remoteTraitIds ) {
            remoteTraitIds = JSON.parse(remoteTraitIds);
            let i_cid = value.trait.cid;
            if( remoteTraitIds.indexOf(i_cid) == -1 ) {

              if( !runningUpdateStack || undefined !== runningUpdateStack[i_cid] && runningUpdateStack[i_cid] ) {
                runningUpdateStack[i_cid] = value;
                
                if( !stackTimeInterval || stackTimeInterval.length == 0 ) {
                  stackTimeInterval[0] = setInterval(() => {
                    if( runningUpdateStack && lastExecutedAt < new Date().valueOf() - 300 ) {
                      for( let i__cid in runningUpdateStack ) {
                        lastExecutedAt = new Date().valueOf();
                        this.traitUpdate(runningUpdateStack[i__cid]);
                        delete runningUpdateStack[i__cid];
                      }
                      
                      clearInterval(stackTimeInterval[0]);
                    }
                  }, 160);
                }
                
              } else {

                for( let i__cid in runningUpdateStack ) {
                  lastExecutedAt = new Date().valueOf();
                  this.traitUpdate(runningUpdateStack[i__cid]);
                  delete runningUpdateStack[i__cid];
                }

                runningUpdateStack[i_cid] = value;
              }
            } 
          }
        });

        //   const updateTrait = debounce((value, editor) => {
        //     const i_component = value.component;
        //     const trait = value.trait;
        //     const ElmToRerender = i_component.attributes.component;
        //     window.gpEditor.Panels.getButton("options", "publishBtn").set("active", false);
        //     window.gpEditor.Panels.getButton("options", "savebtn").set("active", false);

        //     if (ElmToRerender) {
        //         //console.log('bbbbbb')
        //         i_component.attributes.attributes[trait.attributes.name] = trait.attributes.value;
        //         editor.store();

        //         let ElmRender = <ElmToRerender {...i_component.attributes.attributes} />
        //         if (i_component.view)
        //             ReactDOM.render(ElmRender, i_component.view.el);
        //     }
        // }, 300); // Adjust debounce time as needed (in milliseconds)

        // Attach the debounced function to the event listener
        // this.em.on('trait:update', (value) => {
        //     updateTrait(value, editor);
        // });

        this.em.on('component:remove', component => {
            const traits = component.get('traits');
            let localTraitIds = [];
            traits.each(trait => {
              localTraitIds.push(trait.cid);
            });

            let remoteTraitIds = localStorage.getItem("traitIds");
            if( !remoteTraitIds ) {
              remoteTraitIds = [];
            } else {
              remoteTraitIds = JSON.parse(remoteTraitIds);
            }

            localStorage.setItem( "traitIds", JSON.stringify([...new Set(remoteTraitIds.concat(localTraitIds))]));

        });

        this.em.Commands.add('tlb-clone', {
          run(editor, sender, opts) {
              const selected = editor.getSelected();
              if (!selected) return;
              const cloned = selected.clone();
              cloned.addAttributes({ 'data-cloned': 'true' });
              const toolbar = cloned.get('toolbar') || [];
              const filteredToolbar = toolbar.filter( t => t.command !== 'tlb-delete' );
              filteredToolbar.push( {attributes: { class: 'fa fa-trash' },command: 'tlb-delete'} );
              cloned.set('toolbar', filteredToolbar);
              cloned.set({removable: true});
              cloned.removeClass('non-removeable');

              const parent = selected.parent();
              const index = parent.components().indexOf(selected);
              parent.components().add(cloned, { at: index + 1 });
          }
        });

        this.em.on('component:add', component => {

          setTimeout(() => {
            const traits = component.get('traits');
            let localTraitIds = [];
            traits.each(trait => {
              localTraitIds.push(trait.cid);
            });

            let remoteTraitIds = localStorage.getItem("traitIds");
            if( !remoteTraitIds ) {
              remoteTraitIds = [];
            } else {
              remoteTraitIds = JSON.parse(remoteTraitIds);
            }
            localStorage.setItem( "traitIds", JSON.stringify(remoteTraitIds.filter(x => !localTraitIds.includes(x))));
            
            if (component.attributes && component.attributes.attributes && component.attributes.attributes.layout && component.attributes.attributes.name) {
              let layout = component.attributes.attributes.layout;
              if (layout) {
                updateSessionObject('layoutMetaData', layout);
                let i_css = getFormattedCss(layout.css);
                loadStyles(window.gpEditor, i_css);
              }
            }
            if (component.attributes && (component.attributes.type == 'Ad Scripts' || component.attributes.type == 'Ad Scripts Two')) {
              if (typeof component.attributes.attributes.compHeight === 'undefined')
                component.attributes.attributes.compHeight = component.view.el.offsetHeight
              if (typeof component.attributes.attributes.compWidth === 'undefined')
                component.attributes.attributes.compWidth = component.view.el.offsetWidth
              if (typeof component.attributes.attributes.parentHeight === 'undefined')
                component.attributes.attributes.parentHeight = component.parent().view.el.offsetHeight
              if (typeof component.attributes.attributes.parentWidth === 'undefined')
                component.attributes.attributes.parentWidth = component.parent().view.el.offsetWidth
            }
            //add the global settings if it is not present
            // if (editor.getComponents().filter((global) => global.attributes && global.attributes.tagName == 'globalsettingcomponent').length == 0) {
            //   editor.runCommand('save-global-command');
            // }
            // if(component.attributes && component.attributes.attributes && window.gpEditor.getComponents()){
            //   window.gpEditor.getComponents().forEach((global) => {
            //     if(global.attributes && global.attributes.tagName == 'globalsettingcomponent'){
            //       const GLOBAL_DATA = global.attributes.attributes.GLOBAL_DATA;
            //       component.addAttributes({ GLOBAL_DATA });
            //     }
            //   });
            // }

          }, 300);

        });

        this.em.on('component:selected', (ref) => {
          const comp = ref.parents().filter(e => e.attributes.attributes.type == "LAYOUT")[0];
          if (comp) {
            comp.view.el.click();
          } else {
            if (ref.get("type")) {
              ref.em.Panels.getButton('views', 'open-tm_1').set('active', true);
              document.querySelector(".gjs-traits-label").innerHTML = ref.attributes.type ? ref.attributes.type + " Properties" : "Component Settings"
            } else {
              window.gpEditor && window.gpEditor.runCommand("core:component-enter")
            }
            if (ref.attributes.type == "Page Not Found") {
              setTimeout(() => {
                document.querySelector(".gjs-trt-traits").innerHTML = "This is a predefined component";
              })
            }
          }
        });

        let compName = this.model.attributes.component.name ? this.model.attributes.component.name : this.model.attributes.type;
        if (compName) {
          compName = compName.replace(/ /g, "");
        }

        if (STYLES && STYLES[compName] !== undefined && STYLES[compName]) {
          let stylesheets = STYLES[compName];
          stylesheets.forEach((sheet, index) => {
            appendStyleSheet(compName + '' + index, sheet);
          });
        }
        const { model, el } = this;
        this.updateAttributes();
        this.renderChildren();
        const reactEl = this.createReactEl(model.get('component'), {
          ...model.get('attributes')
        });
        this.mountReact(reactEl, el);
        const chld = el.querySelector(`span[${wrpChld}]`);

        // If the container is found, the react component is able to render children
        if (chld) {
          const chldCont = this.getChildrenContainer();
          while (chldCont.firstChild) {
            chld.appendChild(chldCont.firstChild);
          }
        }
        return this;
      },

      // Clean up React component on removal
      onRemove() {
        ReactDOM.unmountComponentAtNode(this.el);
      },

      __upRender() {
        clearTimeout(this._upr);
        this._upr = setTimeout(() => this.render());
      }
    }
  });
};
