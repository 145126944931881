import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, Typography, IconButton, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState,useCallback } from 'react';
import Button from '@mui/material/Button';
import { Radio, RadioGroup, Tabs, Tab } from '@mui/material';
import { httpMiddleware } from './Utils';
import "./MenuManagementComponent.css"
import { SITE_LANGUAGES } from './constants';
import SearchBox from './SearchBox';
import { deepCopy } from '../utilities/object_utils';
import { PAGE_BUILDER_ENDPOINTS, RENDERING_ENDPOINTS } from '../core/constants'
import { saveandupdateMenu } from './Utils';
import { hashSync } from 'm360-react-components';
import _debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const MenuManagementComponent = (props) => {
  const [menuData, setMenuData] = useState([]);
  const [accodionMenuData, setAccodionMenuData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [searchoptions, setSearchOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suggestion, setSuggestion] = useState(false);
  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [mainRadiovalue, setMainRadioValue] = useState('Home');
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [isSubToggleOn, setIsSubToggleOn] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [subexpandedAccordion, setSubExpandedAccordion] = useState(null);
  const [activesearchbox, setactivesearchbox] = useState([0, 0]);
  const [sectionQuery, setSectionQuery] = useState('');
  const [submenuaddition, setSubmenuaddition] = useState(false);
  const [radiomenu, setradiomenu] = useState(1);
  const [searchsectionID, setSerchsectionID] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  
  //const [sectionSearchQuery, setSectionSearchQuery] = useState({});
  // first element of active search box in level and second is index ex: [1,0]

  async function fetchSectionData(searchQuery, index) {
    const retrievedHostId = sessionStorage.getItem('hostId');
    let url = `${PAGE_BUILDER_ENDPOINTS.DENMARK_SEARCH_NEWSROOM}?hostId=${retrievedHostId}&searchTerms=` + searchQuery
    const config = {
      url: url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    httpMiddleware(config)
      .then((response) => {
        setSearchOptions(response);
        setSuggestion(true); // Reset suggestion clicked state
      })
      .catch((error) => {
        console.error("API Error:", error);
        return null;
      });

  }

  useEffect(() => {
    if (inputValue !== '' && inputValue.length > 3) {
      fetchSectionData();
    } else {
      setSearchOptions([]);
      setSuggestion(false);
      setSuggestionClicked(false)
    }
  }, [inputValue]);

  const handleAccordionChange = (index) => {
    setExpandedAccordion(expandedAccordion === index ? null : index);
  };
  const handleSubAccordionChange = (index) => {
    setSubExpandedAccordion(subexpandedAccordion === index ? null : index);
  };

  const handlesearchQueryDebounce=(searchQuery,index)=>{
    fetchSectionData(searchQuery, index);
  };

  const debounceFnSearch = useCallback(_debounce(handlesearchQueryDebounce, 500), []);

  const handleSearchChangel1 = (index, parentIndex, event) => {
    const searchQuery = event.target.value || '';
    let sections = [...accodionMenuData]; // Copy the state array
    let subsections;
  
    if (parentIndex !== -1 && sections[parentIndex]) {
      subsections = sections[parentIndex].sections;
    }
  
    if (subsections) {
      sections = subsections;
    }
  
    if (sections[index]) {
      sections[index]["searchQuery"] = searchQuery;
      sections[index]["secid"] = searchQuery;
    }
  
    // Update the state
    setAccodionMenuData(prevData => {
      const newData = [...prevData];
      if (parentIndex !== -1 && newData[parentIndex]) {
        newData[parentIndex].sections = sections;
      } else {
        newData[index] = sections[index];
      }
      return newData;
    });
  
    if (searchQuery !== '' && searchQuery.length > 3) {
      debounceFnSearch(searchQuery, index);
    } else {
      setSearchOptions([]);
      setSuggestion(false);
      setSuggestionClicked(false);
    }
  };
  const handleSearchChangel2 = (index, parentIndex, event) => {
    let searchQuery = event.target.value || '';
    setSectionQuery(searchQuery)
    if (searchQuery !== '' && searchQuery.length > 3) {
      // fetchSectionData(searchQuery, index);
      debounceFnSearch(searchQuery, index);
    } else {
      setSearchOptions([]);
      setSuggestion(false);
      setSuggestionClicked(false)
    }
  };

  async function fetchWuNode(msid) {
    const retrievedHostId = sessionStorage.getItem('hostId');
    const apiUrl = `${PAGE_BUILDER_ENDPOINTS.MENU_MANAGEMENT_SEOPATH_API}?msid=${msid}&fv=1000&hostId=${retrievedHostId}&apiType=d`;
    try {
      let headerString = '?' + apiUrl.split("?")[1];
      headerString = hashSync(headerString, 6);
      const headers = {'p-nonce': headerString};

      const response = await fetch(apiUrl, { headers: headers });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const wuNode = data?.it?.wu;
      return wuNode;
    } catch (error) {
      console.error("Error fetching wu node:", error);
      return null;
    }
  }
  const handleResultClickl1 = (index, parentIndex, result) => {
    setSuggestion(false);
    fetchWuNode(result?.msid)
      .then(wuNode => {
        if (wuNode) {
          let sections = accodionMenuData;
          if (parentIndex !== -1) {
            sections = accodionMenuData && accodionMenuData[parentIndex]?.sections;
          }
          const values = sections ? [...sections] : [];
          let secid = `${result.msid}`;
          let path = wuNode; // Assuming wuNode contains the path
          values[index].secid = secid;
          values[index].path = path;
          values[index].searchQuery = secid;
          setSearchOptions([]); // Clear the options after selecting a result
          setSuggestionClicked(true);
        } else {
          console.log("Failed to fetch wuNode.");
        }
      });
  };

  const handleResultClickl2 = (index, parentIndex, result) => {
    setSuggestion(false);
    let path = `${result.path} - ${result.msid}`
    if (mainRadiovalue == 'Section') {
      setSerchsectionID(result.msid)
    }
    setSectionQuery(path)
    setSearchOptions([]);
    //setSuggestion(false); // Set suggestion clicked state
    setSuggestionClicked(true)
       const retrievedHostId = sessionStorage.getItem('hostId');
    const sectionAPI = `${RENDERING_ENDPOINTS.MENU_MANAGEMENT_SECTION_SEARCH_API}?&hostId=${retrievedHostId}&key=section-${result.msid}&fv=1`
    fetchData(sectionAPI).then(data => {
      if (!Array.isArray(data) || data.length === 0) {
        setMenuData([]);
        setAccodionMenuData([]);
        return;
      }
      data && data.forEach(l1section => {
        if (!l1section.type) {
          l1section.type = 1
        }
        l1section?.sections?.forEach(l2section => {
          if (!l2section.type) {
            l2section.type = 1
          }
        })
      });
      const accordianData = deepCopy(data)
      setMenuData(data);
      setAccodionMenuData(accordianData);
    });

  };

  const handleButtonClick = async (type) => {
    setIsButtonDisabled(true); // Disable the button

    try {
      if (tabvalue === 0) {
        if (mainRadiovalue === 'Home') {
          await saveandupdateMenu(accodionMenuData, 'header');
        } else {
          await saveandupdateMenu(accodionMenuData, `section-${searchsectionID}`);
        }
      } else if (tabvalue === 1) {
        await saveandupdateMenu(accodionMenuData, 'footer');
      }
    } catch (error) {
      console.error("Error during save/update:", error);
    } finally {
      setIsButtonDisabled(false); // Re-enable the button after the operation completes
    }
  };
  const fetchData = async (apiURL) => {
    try {
      let headerString = '?' + apiURL.split("?")[1];
      headerString = hashSync(headerString, 6);
      const headers = {'p-nonce': headerString};

      const response = await fetch(apiURL, { headers: headers });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      return data;

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const retrievedHostId = sessionStorage.getItem('hostId');
    fetchData(`${RENDERING_ENDPOINTS.MENU_MANAGEMENT_HEADER_API}?hostId=${retrievedHostId}&key=header&fv=1`).then(data => {
      if (!Array.isArray(data) || data.length === 0) {
        return;
      }
      data.forEach(l1section => {
        if (!l1section.type) {
          l1section.type = 1
        }
        l1section?.sections?.forEach(l2section => {
          if (!l2section.type) {
            l2section.type = 1
          }
        })
      });
      const accordianData = deepCopy(data)
      setMenuData(data);
      setAccodionMenuData(accordianData);
    })
  }, []);
  const handleReorderClick = () => {
    setIsToggleOn(!isToggleOn); // Toggle the state
  };
  const handleSubReorderClick = () => {
    setIsSubToggleOn(!isSubToggleOn); // Toggle for subsections
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setAccodionMenuData([]);
    const retrievedHostId = sessionStorage.getItem('hostId');
    const apiUrl = newValue === 1
      ? `${RENDERING_ENDPOINTS.MENU_MANAGEMENT_HEADER_API}?hostId=${retrievedHostId}&key=footer&fv=1`
      : `${RENDERING_ENDPOINTS.MENU_MANAGEMENT_HEADER_API}?hostId=${retrievedHostId}&key=header&fv=1`;
    if (newValue == 0) {
      fetchData(apiUrl).then(data => {
        if (!Array.isArray(data) || data.length === 0) {
          return;
        }
        data.forEach(l1section => {
          if (!l1section.type) {
            l1section.type = 1
          }
          l1section?.sections?.forEach(l2section => {
            if (!l2section.type) {
              l2section.type = 1
            }
          })
        });
        const accordianData = deepCopy(data)
        setMenuData(data);
        setAccodionMenuData(accordianData);
        setInputValue('');

        setExpandedAccordion(null)
        setMainRadioValue('Home')
      })

    }
    else if (newValue == 1) {
      fetchData(apiUrl).then(data => {
        data.forEach(l1section => {
          if (!l1section.type) {
            l1section.type = 1
          }
          l1section?.sections?.forEach(l2section => {
            if (!l2section.type) {
              l2section.type = 1
            }
          })
        });
        const accordianData = deepCopy(data)
        setAccodionMenuData(accordianData);
        setInputValue('');
        setExpandedAccordion(null)
        setMainRadioValue('Home')
      })
    }
  };

  const handleMainRadioChange = (event) => {
    const retrievedHostId = sessionStorage.getItem('hostId');
    setMainRadioValue(event.target.value);
    if (event.target.value == 'Home') {
      setMenuData([]);
      const apiUrl = `${RENDERING_ENDPOINTS.MENU_MANAGEMENT_HEADER_API}?hostId=${retrievedHostId}&key=header&fv=1`
      fetchData(apiUrl).then(data => {
        if (!Array.isArray(data) || data.length === 0) {
          return;
        }
        data.forEach(l1section => {
          if (!l1section.type) {
            l1section.type = 1
          }
          l1section?.sections?.forEach(l2section => {
            if (!l2section.type) {
              l2section.type = 1
            }
          })
        });
        const accordianData = deepCopy(data)
        setMenuData(data);
        setAccodionMenuData(accordianData);
        setInputValue('');
        setSectionQuery('')
        setExpandedAccordion(null)
        setMainRadioValue('Home')
      })
    }
    else {
      setMenuData([])
      setAccodionMenuData([]);
      setInputValue('')
    }
    setExpandedAccordion(null)

  };

  const handleAddMainMenuItem = () => {
    const newMenuData = [...menuData, { "name": "New Main Menu", "secid": "", "path": "", "type": 1, "tagpath": "" }];
    setMenuData(newMenuData);
    const newaccordianData = [...accodionMenuData, { "name": "New Main Menu", "secid": "", "path": "", "type": 1, "tagpath": "" }];
    setAccodionMenuData(newaccordianData);
  };

  const handleEditMainMenuItem = (index, newName, newPath, type) => {
    const newMenuData = [...menuData];
    const newaccordianData = [...accodionMenuData];
    newMenuData[index].name = newName;
    newMenuData[index].path = newPath;
    newMenuData[index].type = type;
    setMenuData(newMenuData);
    newaccordianData[index].name = newName;
    newaccordianData[index].path = newPath;
    newaccordianData[index].type = type;
    setAccodionMenuData(newaccordianData);
  };

  const handleDeleteMainMenuItem = (index) => {
    let data = deepCopy(accodionMenuData)
    data.splice(index, 1);
    setAccodionMenuData(data)
  };

  const handleAddSubMenuItem = (parentIndex) => {
  // Create a new copy of accodionMenuData to avoid direct mutation
  const updatedMenuData = [...accodionMenuData];
  
  if (!updatedMenuData[parentIndex].sections) {
    updatedMenuData[parentIndex].sections = [];
  }
    const newSubMenuItem = { "name": "New Submenu", "secid": "", "path": "/", "type": accodionMenuData[parentIndex].type };
    updatedMenuData[parentIndex].sections.push(newSubMenuItem);
    setMenuData(updatedMenuData);
    setSubmenuaddition(true)
    setAccodionMenuData(updatedMenuData);
  };

  const handleEditSubMenuItem = (parentIndex, index, newName, newPath, type) => {
    const newMenuData = [...menuData];
    newMenuData[parentIndex].sections[index].name = newName;
    newMenuData[parentIndex].sections[index].path = newPath;
    newMenuData[parentIndex].sections[index].type = type;
    setMenuData(newMenuData);
    setAccodionMenuData(newMenuData);
  };

  const handleDeleteSubMenuItem = (parentIndex, index) => {
    let data = deepCopy(accodionMenuData)
    data[parentIndex].sections.splice(index, 1);
    setAccodionMenuData(data)
  };
  const moveAccordionUp = (parentIndex, index) => {
    if (index === 0) return; // Already at the top, do nothing
    let data = deepCopy(accodionMenuData)
    let sections = data;
    if (parentIndex !== -1) {
      sections = data && data[parentIndex]?.sections;
    }
    const temp = sections[index];
    sections[index] = sections[index - 1];
    sections[index - 1] = temp;
    setMenuData(data);
    setAccodionMenuData(data);
  };

  const moveAccordionDown = (parentIndex, index) => {
    if (index === menuData.length - 1) return; // Already at the bottom, do nothing
    let data = deepCopy(accodionMenuData)
    let sections = data;
    if (parentIndex !== -1) {
      sections = data && data[parentIndex]?.sections;
    }
    const temp = sections[index];
    sections[index] = sections[index + 1];
    sections[index + 1] = temp;
    setMenuData(data);
    setAccodionMenuData(data);
  };

  const handleRadiochage = (event, level, parentIndex, index) => {
    const radiovalue = parseInt(event.target.value);
    let updatedData = deepCopy(accodionMenuData);
  
    // Determine the sections or subsections to update
    let sections = parentIndex !== -1 ? updatedData[parentIndex]?.sections : updatedData;
  
    // Ensure the index is valid and update the type value
    if (sections && index >= 0 && index < sections.length) {
      sections[index].type = radiovalue;
    } else {
      console.error("Invalid index or sections not found");
    }
  
    // If working with subsections, update the parent; otherwise, update directly
    if (parentIndex !== -1 && updatedData[parentIndex]) {
      updatedData[parentIndex].sections = sections;
    }
  
    setAccodionMenuData(updatedData); // Update the state with the modified data
  };
  

  const handleSearchChangeTags = (index, parentIndex, event) => {
    let searchQuery = event.target.value || '';
    let sections = [...accodionMenuData]; // Copy the state array
    let subsections;
  
    if (parentIndex !== -1 && sections[parentIndex]) {
      subsections = sections[parentIndex].sections;
    }
  
    if (subsections) {
      sections = subsections;
    }
  
    const values = sections ? [...sections] : [];
    
    // Check if index is within bounds
    if (index >= 0 && index < values.length) {
      values[index]["searchQuery"] = searchQuery;
      values[index]["tagpath"] = searchQuery;
  
      // Update the state
      setAccodionMenuData(prevData => {
        const newData = [...prevData];
        if (parentIndex !== -1 && newData[parentIndex]) {
          newData[parentIndex].sections = values;
        } else {
          newData[index] = values[index];
        }
        return newData;
      });
  
      if (searchQuery !== '' && searchQuery.length > 3) {
        // fetchSectionData(searchQuery, index);
      } else {
        setSearchOptions([]);
        setSuggestion(false);
        setSuggestionClicked(false);
      }
    } else {
      console.error("Invalid index:", index);
    }
  };



  const handleSearchChangeTagsL2 = (index, parentIndex, event) => {
    let searchQuery = event.target.value || '';
    // Create a copy of the original data
    const updatedMenuData = [...accodionMenuData];
    // Check if there's a parentIndex provided
    if (parentIndex !== -1) {
      // If there's a parentIndex, find the corresponding sections array
      const parentSection = updatedMenuData[parentIndex];
      if (parentSection && parentSection.sections) {
        // Update the searchQuery and tagpath in the sections array
        //parentSection.sections[index].searchQuery = searchQuery;
        parentSection.sections[index].tagpath = searchQuery;
      }
    } else {
      // If there's no parentIndex, update the searchQuery and tagpath in the main array
      //updatedMenuData[index].searchQuery = searchQuery;
      updatedMenuData[index].tagpath = searchQuery;
    }
    // Update the state with the modified data
    setAccodionMenuData(updatedMenuData);
    if (searchQuery !== '' && searchQuery.length > 3) {
      //fetchSectionData(searchQuery, index);
    } else {
      setSearchOptions([]);
      setSuggestion(false);
      setSuggestionClicked(false);
    }
  };


  const handleSearchChangePath = (index, parentIndex, event) => {
    const searchQuery = event.target.value || '';
    let sections = [...accodionMenuData]; // Copy the state array
    let subsections;
  
    if (parentIndex !== -1 && sections[parentIndex]) {
      subsections = sections[parentIndex].sections;
    }
  
    if (subsections) {
      sections = subsections;
    }
  
    const values = sections ? [...sections] : [];
    
    // Check if index is within bounds
    if (index >= 0 && index < values.length) {
      values[index]["searchQuery"] = searchQuery;
      values[index]["path"] = searchQuery;
  
      // Update the state
      setAccodionMenuData(prevData => {
        const newData = [...prevData];
        if (parentIndex !== -1 && newData[parentIndex]) {
          newData[parentIndex].sections = values;
        } else {
          newData[index] = values[index];
        }
        return newData;
      });
  
      if (searchQuery !== '' && searchQuery.length > 3 && accodionMenuData[index]?.type !== 3) {
        // debounceFnSearch(searchQuery, index);
      } else {
        setSearchOptions([]);
        setSuggestion(false);
        setSuggestionClicked(false);
      }
    } else {
      console.error("Invalid index:", index);
    }
  };


  return (
    <div className='menumgmtMain'>
      <div className='menuHeaderTxt'>Menu Management</div>
      <div>
        <div>
          <Tabs value={tabvalue} onChange={handleTabChange}>
            <Tab label="Header Menu" />
            <Tab label="Footer Menu" />
          </Tabs>
        </div>
        {tabvalue == 0 &&
          <div>
            <div>
              <RadioGroup row value={mainRadiovalue} onChange={handleMainRadioChange} >
                <FormControlLabel value="Home" control={<Radio />} label="Home" />
                {/* <FormControlLabel value="Section" control={<Radio />} label="Section" /> */}
                <FormControlLabel
                  value="start"
                  control={<Switch color="primary" checked={isToggleOn} onChange={handleReorderClick} />}
                  label="Reorder"
                  labelPlacement="start"
                />
              </RadioGroup>
            </div>
            {mainRadiovalue == 'Home' && <div>
              {accodionMenuData && accodionMenuData.map((menuItem, index) => (
                <Accordion key={index} expanded={expandedAccordion === index} onChange={() => handleAccordionChange(index)} className="accmenu">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {isToggleOn &&
                      <>
                        <Tooltip title="Move Up">
                          <IconButton onClick={(e) => { e.stopPropagation(); moveAccordionUp(-1, index); }} className="updownbtn" >
                            <ArrowUpwardIcon className='arrowup' fontSize="smaller" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Move Down">
                          <IconButton onClick={(e) => { e.stopPropagation(); moveAccordionDown(-1, index); }}>
                            <ArrowDownwardIcon className='arrowdown' fontSize="smaller" />
                          </IconButton>
                        </Tooltip>
                      </>}
                    <Typography>{menuItem.name}</Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div >
                        <div>
                          <input type="text" label="Main Menu Name" value={menuItem.name} onChange={(e) => {
                            const newName = e.target.value;
                            handleEditMainMenuItem(index, newName, menuItem.path, menuItem.type);
                          }} />
                        </div>
                        <Typography variant="h6" align="left">Mapping:</Typography>
                        <RadioGroup
                          row
                          aria-label="menu-type"
                          name="menu-type"
                          value={accodionMenuData[index]?.type}
                          onChange={(e) => handleRadiochage(e, 1, -1, index)}
                          level={1}
                          parentIndex={-1}
                          radioindex={index}
                        >
                          <FormControlLabel value={1} control={<Radio />} label="Section" />
                          <FormControlLabel value={2} control={<Radio />} label="Tag" />
                          <FormControlLabel value={3} control={<Radio />} label="Link" />
                        </RadioGroup>
                        <div>
                          {accodionMenuData[index]?.type == 1 && <SearchBox
                            inputValue={accodionMenuData[index]?.secid != '' ? accodionMenuData[index]?.secid : []}
                            loading={loading}
                            options={searchoptions}
                            suggestionClicked={suggestionClicked}
                            handleSearchChange={handleSearchChangel1}
                            handleResultClick={handleResultClickl1}
                            searchboxindex={index}
                            level={1}
                            setactivesearchbox={setactivesearchbox}
                            activesearchbox={activesearchbox}
                            parentIndex={-1}
                          />}
                          {accodionMenuData[index]?.type == 2 &&
                            <Menutag
                              inputValue={accodionMenuData[index]?.tagpath || ''}
                              suggestionClicked={suggestionClicked}
                              handleSearchChange={handleSearchChangeTags}
                              searchboxindex={index}
                              level={1}
                              setactivesearchbox={setactivesearchbox}
                              activesearchbox={activesearchbox}
                              parentIndex={-1}
                              accodionMenuData={accodionMenuData}
                              setAccodionMenuData={setAccodionMenuData}
                            />
                          }
                          {accodionMenuData[index]?.type == 3 &&
                            <Linktag
                              inputValue={accodionMenuData[index]?.path || ''}
                              suggestionClicked={suggestionClicked}
                              handleSearchChange={handleSearchChangePath}
                              searchboxindex={index}
                              level={1}
                              setactivesearchbox={setactivesearchbox}
                              activesearchbox={activesearchbox}
                              parentIndex={-1}
                            />
                          }
                        </div>
                      </div>
                      <div className='deleteIcon'>
                        
                        {/* <i className="fas fa-trash-alt" onClick={() => handleDeleteMainMenuItem(index)} ></i> */}
                        <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteMainMenuItem(index)} />
                      </div>

                      {menuItem && menuItem?.sections && menuItem?.sections.length > 0 &&
                        <div className='subTogglebtn'>
                          <Typography variant="h3" align="left" fontWeight="bold" style={{ paddingBottom: '10px' }}>
                            Submenu
                          </Typography>
                          <FormControlLabel
                            value="start"
                            control={<Switch color="primary" checked={isSubToggleOn} onChange={handleSubReorderClick} />}
                            label="Reorder"
                            labelPlacement="start"
                          />
                        </div>
                      }
                      {/* home submenu code starts */}
                      {menuItem.sections && menuItem.sections.map((subMenuItem, subIndex) => (
                        <div style={{ border: '1px solid #cccccc' }}>
                          <Accordion key={subIndex} expanded={subexpandedAccordion === subIndex} onChange={() => handleSubAccordionChange(subIndex)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              {isSubToggleOn &&
                                <>
                                  <Tooltip title="Move Up">
                                    <IconButton onClick={(e) => { e.stopPropagation(); moveAccordionUp(index, subIndex); }} className="updownbtn" >
                                      <ArrowUpwardIcon className='arrowup' fontSize="smaller" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Move Down">
                                    <IconButton onClick={(e) => { e.stopPropagation(); moveAccordionDown(index, subIndex); }}>
                                      <ArrowDownwardIcon className='arrowdown' fontSize="smaller" />
                                    </IconButton>
                                  </Tooltip>
                                </>}
                              <Typography>{subMenuItem.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                                <div>
                                  <input type="text" label="Main Menu Name" value={subMenuItem.name} onChange={(e) => {
                                    const newName = e.target.value;
                                    handleEditSubMenuItem(index, subIndex, newName, subMenuItem.path, subMenuItem.type);
                                  }} />
                                </div>
                                <Typography variant="h6" align="left">Mapping:</Typography>
                                <RadioGroup
                                  row
                                  aria-label="menu-type"
                                  name="menu-type"
                                  value={accodionMenuData && accodionMenuData[index]?.sections[subIndex]?.type || 1}
                                  onChange={(e) => handleRadiochage(e, 2, index, subIndex)}
                                  level={2}
                                  parentIndex={index}
                                  radioindex={subIndex}
                                >
                                  <FormControlLabel value={1} control={<Radio />} label="Section" />
                                  <FormControlLabel value={2} control={<Radio />} label="Tag" />
                                  <FormControlLabel value={3} control={<Radio />} label="Link" />
                                </RadioGroup>

                                <div>
                                  {accodionMenuData[index]?.sections[subIndex]?.type == 1 &&
                                    <SearchBox
                                      inputValue={accodionMenuData && accodionMenuData[index]?.sections[subIndex]?.secid || ''}
                                      loading={loading}
                                      options={searchoptions}
                                      suggestionClicked={suggestionClicked}
                                      handleSearchChange={handleSearchChangel1}
                                      handleResultClick={handleResultClickl1}
                                      searchboxindex={subIndex}
                                      level={2}
                                      setactivesearchbox={setactivesearchbox}
                                      activesearchbox={activesearchbox}
                                      parentIndex={index}
                                    />}

                                  {accodionMenuData[index]?.sections[subIndex]?.type == 2 &&
                                    <Menutag
                                      inputValue={accodionMenuData[index]?.sections[subIndex]?.tagpath || ''}
                                      suggestionClicked={suggestionClicked}
                                      handleSearchChange={handleSearchChangeTagsL2}
                                      searchboxindex={subIndex}
                                      level={2}
                                      setactivesearchbox={setactivesearchbox}
                                      activesearchbox={activesearchbox}
                                      parentIndex={index}
                                      accodionMenuData={accodionMenuData}
                                      setAccodionMenuData={setAccodionMenuData}
                                    />

                                  }
                                  {accodionMenuData[index]?.sections[subIndex]?.type == 3 &&
                                    <Linktag
                                      inputValue={accodionMenuData[index]?.sections[subIndex]?.path || ''}
                                      suggestionClicked={suggestionClicked}
                                      handleSearchChange={handleSearchChangePath}
                                      searchboxindex={subIndex}
                                      level={1}
                                      setactivesearchbox={setactivesearchbox}
                                      activesearchbox={activesearchbox}
                                      parentIndex={index}
                                    />
                                  }

                                </div>
                                <div className='deleteIcon'>
                                  
                                  {/* <i className="fas fa-trash-alt" onClick={() => handleDeleteSubMenuItem(index, subIndex)}></i> */}
                                  <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteSubMenuItem(index, subIndex)} />
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ))}
                      <div style={{ textAlign: 'right' }}>
                        <Button variant="contained" onClick={() => handleAddSubMenuItem(index)} size="small" style={{ minWidth: '80px', minHeight: '24px', fontSize: '0.55rem', marginTop: '10px' }}>+ Add Sub Menu</Button>
                      </div>

                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div style={{ textAlign: 'right' }}>
                <Button variant="contained" onClick={handleAddMainMenuItem} size="small" style={{ minWidth: '80px', minHeight: '24px', fontSize: '0.55rem', marginTop: '10px' }}>+ Add Main Menu</Button>
              </div>
            </div>
            }
            {/* SECTION MENU CODE STARTS */}
            {mainRadiovalue == 'Section' &&
              <>
                <div>
                  <SearchBox
                    inputValue={sectionQuery || ''}
                    loading={loading}
                    options={searchoptions}
                    suggestionClicked={suggestionClicked}
                    handleSearchChange={handleSearchChangel2}
                    handleResultClick={handleResultClickl2}
                    searchboxindex={0}
                    level={1000}
                    setactivesearchbox={setactivesearchbox}
                    activesearchbox={activesearchbox}
                    parentIndex={-1}
                  />

                  {accodionMenuData.map((menuItem, index) => (
                    <Accordion key={index}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {isToggleOn &&
                          <div className='accmenu'>
                            <Tooltip title="Move Up">
                              <IconButton onClick={(e) => { e.stopPropagation(); moveAccordionUp(-1 ,index); }}>
                                <ArrowUpwardIcon className='arrowup' fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Move Down">
                              <IconButton onClick={(e) => { e.stopPropagation(); moveAccordionDown(-1 ,index); }}>
                                <ArrowDownwardIcon className='arrowdown' fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>}
                        <Typography>{menuItem.name}</Typography>

                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <div>
                            <input type="text" label="Main Menu Name" value={menuItem.name} onChange={(e) => {
                              const newName = e.target.value;
                              handleEditMainMenuItem(index, newName, menuItem.path, menuItem.type);
                            }} />
                          </div>
                          <Typography variant="h6" align="left">Mapping:</Typography>
                          <RadioGroup
                            row
                            aria-label="menu-type"
                            name="menu-type"
                            value={accodionMenuData[index]?.type || '' || 1}
                            onChange={(e) => handleRadiochage(e, 1, -1, index)}
                            level={1}
                            parentIndex={index}
                            radioindex={1}
                          >
                            <FormControlLabel value={1} control={<Radio />} label="Section" />
                            <FormControlLabel value={2} control={<Radio />} label="Tag" />
                            <FormControlLabel value={3} control={<Radio />} label="Link" />
                          </RadioGroup>
                          <div>
                            {accodionMenuData[index]?.type == 1 &&
                              <SearchBox
                                inputValue={accodionMenuData[index]?.secid || ''}
                                loading={loading}
                                options={searchoptions}
                                suggestionClicked={suggestionClicked}
                                handleSearchChange={handleSearchChangel1}
                                handleResultClick={handleResultClickl1}
                                searchboxindex={index}
                                level={1}
                                setactivesearchbox={setactivesearchbox}
                                activesearchbox={activesearchbox}
                                parentIndex={-1}
                              />
                            }
                            {accodionMenuData[index]?.type == 2 &&

                              <Menutag
                                inputValue={accodionMenuData[index]?.tagpath || ''}
                                suggestionClicked={suggestionClicked}
                                handleSearchChange={handleSearchChangeTags}
                                searchboxindex={index}
                                level={1}
                                setactivesearchbox={setactivesearchbox}
                                activesearchbox={activesearchbox}
                                parentIndex={-1}
                                accodionMenuData={accodionMenuData}
                                setAccodionMenuData={setAccodionMenuData}
                              />
                            }
                            {accodionMenuData[index]?.type == 3 &&
                              <Linktag
                                inputValue={accodionMenuData[index]?.path || ''}
                                loading={loading}
                                options={searchoptions}
                                suggestionClicked={suggestionClicked}
                                handleSearchChange={handleSearchChangePath}
                                searchboxindex={index}
                                level={1}
                                setactivesearchbox={setactivesearchbox}
                                activesearchbox={activesearchbox}
                                parentIndex={-1}
                              />
                            }
                          </div>
                          <div className='deleteIcon'>
                            {/* <i className="fas fa-trash-alt" onClick={() => handleDeleteMainMenuItem(index)} ></i> */}
                            <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteMainMenuItem(index)}/>
                          </div>

                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" onClick={handleAddMainMenuItem} size="small" style={{ minWidth: '80px', minHeight: '24px', fontSize: '0.55rem', marginTop: '10px' }}>+ Add Main Menu</Button>
                  </div>
                </div>
              </>
            }
          </div>
        }
        {/* FOOTER MENU CODE STARTS */}
        {tabvalue == 1 &&
          <div className='footermenumgmt'>
            <div className='reorderclass'>
              <FormControlLabel
                value="start"
                control={<Switch color="primary" checked={isToggleOn} onChange={handleReorderClick} />}
                label="Reorder"
                labelPlacement="start"
              />
            </div>
            {accodionMenuData.map((menuItem, index) => (
              <Accordion key={index} expanded={expandedAccordion === index} onChange={() => handleAccordionChange(index)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={(e) => e.stopPropagation()} // Prevents expansion
                >
                  {isToggleOn &&
                    <div className='accmenu'>
                      <Tooltip title="Move Up">
                        <IconButton onClick={(e) => { e.stopPropagation(); moveAccordionUp(-1 ,index); }}>
                          <ArrowUpwardIcon className='arrowup' fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Move Down">
                        <IconButton onClick={(e) => { e.stopPropagation(); moveAccordionDown(-1 ,index); }}>
                          <ArrowDownwardIcon className='arrowdown' fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>}
                  <Typography>{menuItem.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <div>
                      <input type="text" label="Main Menu Name" value={menuItem.name} onChange={(e) => {
                        const newName = e.target.value;
                        handleEditMainMenuItem(index, newName, menuItem.path, menuItem.type);
                      }} />
                    </div>
                    <Typography variant="h6" align="left">Mapping:</Typography>
                    <RadioGroup
                      row
                      aria-label="menu-type"
                      name="menu-type"
                      value={accodionMenuData[index]?.type || '' || 1}
                      onChange={(e) => handleRadiochage(e, 1, -1, index)}
                      level={1}
                      parentIndex={index}
                      radioindex={1}
                    >
                      <FormControlLabel value={1} control={<Radio />} label="Section" />
                      <FormControlLabel value={2} control={<Radio />} label="Tag" />
                      <FormControlLabel value={3} control={<Radio />} label="Link" />
                    </RadioGroup>
                    <div>
                      {accodionMenuData[index]?.type == 1 &&
                        <SearchBox
                          inputValue={accodionMenuData[index]?.secid || ''}
                          loading={loading}
                          options={searchoptions}
                          suggestionClicked={suggestionClicked}
                          handleSearchChange={handleSearchChangel1}
                          handleResultClick={handleResultClickl1}
                          searchboxindex={index}
                          level={1}
                          setactivesearchbox={setactivesearchbox}
                          activesearchbox={activesearchbox}
                          parentIndex={-1}
                        />
                      }

                      {accodionMenuData[index]?.type == 2 &&
                        <Menutag
                          inputValue={accodionMenuData[index]?.tagpath || ''}
                          loading={loading}
                          options={searchoptions}
                          suggestionClicked={suggestionClicked}
                          handleSearchChange={handleSearchChangeTags}
                          searchboxindex={index}
                          level={1}
                          setactivesearchbox={setactivesearchbox}
                          activesearchbox={activesearchbox}
                          parentIndex={-1}
                          accodionMenuData={accodionMenuData}
                          setAccodionMenuData={setAccodionMenuData}
                        />
                      }
                      {accodionMenuData[index]?.type == 3 &&
                        <Linktag
                          inputValue={accodionMenuData[index]?.path || ''}
                          loading={loading}
                          options={searchoptions}
                          suggestionClicked={suggestionClicked}
                          handleSearchChange={handleSearchChangePath}
                          searchboxindex={index}
                          level={1}
                          setactivesearchbox={setactivesearchbox}
                          activesearchbox={activesearchbox}
                          parentIndex={-1}
                        />
                      }

                    </div>
                    <div className='deleteIcon'>
                      {/* <i className="fas fa-trash-alt" onClick={() => handleDeleteMainMenuItem(index)} ></i> */}
                      <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteMainMenuItem(index)}/>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
            <div style={{ textAlign: 'right' }}>
              <Button variant="contained" onClick={handleAddMainMenuItem} size="small" style={{ minWidth: '80px', minHeight: '24px', fontSize: '0.55rem', marginTop: '10px' }}>+ Add Footer Menu</Button>
            </div>
          </div>
        }
      </div>
      <div>
        <Button style={{ marginTop: '20px' }} disabled={isButtonDisabled} variant="outlined" onClick={() => handleButtonClick('home')}>Save/Update</Button>
      </div>
    </div>
  );
}

const Menutag = ({
  level,
  inputValue,
  handleSearchChange,
  searchboxindex,
  parentIndex,
  accodionMenuData,
  setAccodionMenuData
}) => {
  const [tagValidity, setTagValidity] = useState(null);

  // const handleFocusOut = async (event) => {
  //   const tagValue = event.target.value.trim();
  //   if (tagValue === '') {
  //     setTagValidity(null);
  //     return;
  //   }
  
  //   const retrievedHostId = sessionStorage.getItem('hostId');
  //   const apiUrl = `${PAGE_BUILDER_ENDPOINTS.MENU_MANAGEMENT_TAGCHECK_API}?hostId=${retrievedHostId}&searchKey=${tagValue}&perPage=1&pageNum=0&type=1001,1024,7,1025`;
  
  //   // Generate the p-nonce value from the API URL
  //   let headerString = `?` + apiUrl.split("?")[1];
  //   headerString = decodeURI(headerString);
  //   headerString = hashSync(headerString, 6); // Ensure you have bcrypt library available
  
  //   const customHeaders = {
  //     'Content-Type': 'application/json',
  //     'p-nonce': headerString
  //   };
  
  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: 'GET',
  //       headers: customHeaders
  //     });
  
  //     const data = await response.json();
  
  //     if (data.cdsStatus === 0 && data.items.length > 0) {
  //       // Tag is valid
  //       setTagValidity('valid');
  //     } else {
  //       // Tag is not valid
  //       setTagValidity('invalid');
  //       const updatedMenuData = [...accodionMenuData]; // Copy the state array
  
  //       if (parentIndex === -1) {
  //         if (updatedMenuData[searchboxindex]) {
  //           updatedMenuData[searchboxindex].tagpath = ''; // Update tagpath to empty string for invalid tag
  //         }
  //       } else if (updatedMenuData[parentIndex]?.sections) {
  //         if (updatedMenuData[parentIndex].sections[searchboxindex]) {
  //           updatedMenuData[parentIndex].sections[searchboxindex].tagpath = ''; // Update tagpath to empty string for invalid tag
  //         }
  //       }
  //       setAccodionMenuData(updatedMenuData);
  //     }
  //   } catch (error) {
  //     console.error("Error occurred while fetching data:", error);
  //   }
  // };

  return (
    <div className="searchBoxcls">
      <input
        type="text"
        value={inputValue}
        onChange={(e) => handleSearchChange(searchboxindex, parentIndex, e)}
        placeholder="Tags"
        // onBlur={handleFocusOut}
      />
      {/* {tagValidity !== null && (
        <span className='validmsg' style={{ color: tagValidity === 'valid' ? 'green' : 'red' }}>
          {tagValidity === 'valid' ? 'Valid Tag' : 'Invalid Tag'}
        </span>
      )} */}
    </div>
  );
};

const Linktag = ({
  inputValue,
  handleSearchChange,
  searchboxindex,
  parentIndex
}) => {
  return (
    <div className='searchBoxcls'>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => handleSearchChange(searchboxindex, parentIndex, e)}
        placeholder="https://"
        style={{ backgroundColor: 'white' }}
        onFocus={onfocus}
      />
    </div>
  );
};

export default MenuManagementComponent;
