import ReactDOM from "react-dom";
import React, { useRef } from "react";
import { createRoot } from "react-dom";
import ReactDOMServer from "react-dom/server";
import { appendScriptToHeadOrBody, getSessionObject, httpMiddleware, getDefaultMsids, checkForDummyIDs, showErrorToast } from "./Utils";
import TypographyHTMLCode from "./../pages/Home/TypographyHTMLCode";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import {GlobalSettingComp} from "../../components/GlobalSetting/GlobalSettingComp";
// import { savePageLayout } from "../../core/Service/LayoutService";
import GlobalSettingComponent from "./GlobalSettingsComponent";
import MenuManagementComponent from "./MenuManagementComponent"

import { PAGE_BUILDER_ENDPOINTS } from "./constants";
import {
  PARENT_COMPONENTS_DATA_MAPPING,
  colorSetOptionsList,
  defaultFontSizes,
  defaultFontWeight,
  API_URLS
} from "./constants";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete, Button, FormControlLabel, Radio, RadioGroup, Switch } from "@mui/material";
import { toast } from 'react-toastify';
import { persistData, logoutUserSession } from "./Utils";
import { getCss } from "./get-css";


let tempTextFieldValue = '';

export const createCustomTraits = (editor) => {
  editor.TraitManager.addType("custom-textarea", {
    // Expects as return a simple HTML string or an HTML element
    onUpdate({ elInput, component }) {
      let { options, value } = this.model.attributes;
      if (typeof component.attributes.attributes.headAdScript != "undefined"
        && typeof component.attributes.attributes.bodyAdScript != "undefined"
        && typeof component.attributes.attributes.bodyAdMobileScript != "undefined"
        && (component.changed?.status == "selected" || Object.keys(component.changed).length === 0)
      ) {
        value = { ...component.attributes.attributes.headAdScript };
        this.model.attributes.value = { ...component.attributes.attributes.headAdScript };

        if (elInput.querySelector('#headAdScript')) {
          elInput.querySelector('#headAdScript').innerHTML = component.attributes.attributes?.headAdScript;
        }
        if (elInput.querySelector('#bodyAdScript')) {
          elInput.querySelector('#bodyAdScript').innerHTML = component.attributes.attributes?.bodyAdScript;
        }
        if (elInput.querySelector('#bodyAdMobileScript')) {
          elInput.querySelector('#bodyAdMobileScript').innerHTML = component.attributes.attributes?.bodyAdMobileScript;
        }
      }
    },

    createInput({ trait }) {
      // Here we can decide to use properties from the trait
      // Create a new element container and add some content
      const el = document.createElement("div");
      el.innerHTML = `
          <textarea id="${trait.attributes.id}" name="${trait.attributes.name}" value="${trait.attributes.id}" rows="4" cols="50"/>
        `;
      el.querySelector("#" + trait.attributes.id).innerHTML = '';

      //Let's make our content interactive
      const inputsUrl = el.querySelector("#" + trait.attributes.id);
      inputsUrl.addEventListener("change", (ev) => {
        let value = ev.target.value.trim();
        this.model.setValue(value);
        ev.stopPropagation();
      });

      return el;
    },
  });

  editor.TraitManager.addType("custom-urlarea", {
    onUpdate({ elInput, trait, component }) {

      if (this.model.target.attributes.type && this.model.target.attributes.type === 'Breaking News'
        && !(trait.attributes.id == "headAdScript" || trait.attributes.id == "bodyAdScript" || trait.attributes.id == "headAdScript2" || trait.attributes.id == "bodyAdScript2" ||
          trait.attributes.id == "bodyAdMobileScript" || trait.attributes.id == "bodyAdMobileScript2")) {
        elInput.querySelector("#" + trait.attributes.id).value = component.attributes.mainHeadingHref;
      } else if (this.model.target.attributes.type &&
        (this.model.target.attributes.type === 'Hero Layout'
          || this.model.target.attributes.type === 'Grid View'
          || this.model.target.attributes.type === 'Carousel Card Block'
          || this.model.target.attributes.type === 'List View'
          || this.model.target.attributes.type === 'Similar Stories'
          || this.model.target.attributes.type === 'Breaking News'
          || this.model.target.attributes.type === 'Article Show'
          || this.model.target.attributes.type === 'Article Show Amp'
          || this.model.target.attributes.type === 'Hero Layout2'
          || this.model.target.attributes.type === 'Grid View2'
          || this.model.target.attributes.type === 'PhotoList Two'
          || this.model.target.attributes.type === 'Related News'
          || this.model.target.attributes.type === 'Quick View Two'
          || this.model.target.attributes.type === 'RhsHome Two'
          || this.model.target.attributes.type === 'RhsHome Other Two'
          || this.model.target.attributes.type === 'WebStories Two'
          || this.model.target.attributes.type === 'Article Show Two Amp'
          || this.model.target.attributes.type === 'Video Show Two Amp'
          || this.model.target.attributes.type === 'Photo Show Two Amp'
          || this.model.target.attributes.type === 'List View Two'
          || this.model.target.attributes.type === 'Article Show Two'
          || this.model.target.attributes.type === 'MediaList Two'
          || this.model.target.attributes.type === 'Slide Show Two'
          || this.model.target.attributes.type === 'WebStoriesList Two'
          || this.model.target.attributes.type === 'Podcast Two'
          || this.model.target.attributes.type === 'GridView Two'
          || this.model.target.attributes.type === 'List View Three'
          || this.model.target.attributes.type === 'Article Three'
          || this.model.target.attributes.type === 'PhotoList Three'
          || this.model.target.attributes.type === 'Quick View Three'
          || this.model.target.attributes.type === 'RhsHome Three'
          || this.model.target.attributes.type === 'RhsHome Other Three'
          || this.model.target.attributes.type === 'WebStories Three'
          || this.model.target.attributes.type === 'Article Show Three Amp'
          || this.model.target.attributes.type === 'Video Show Three Amp'
          || this.model.target.attributes.type === 'Photo Show Three Amp'
          || this.model.target.attributes.type === 'List View Three'
          || this.model.target.attributes.type === 'Article Show Three'
          || this.model.target.attributes.type === 'MediaList Three'
          || this.model.target.attributes.type === 'Slide Show Three'
          || this.model.target.attributes.type === 'WebStoriesList Three'
          || this.model.target.attributes.type === 'Podcast Three'
          || this.model.target.attributes.type === 'GridView Three'
        )
        && trait.attributes.id != "viewAllUrl") {
        let value = "";
        if (trait.attributes.id == "headAdScript") {
          value = component.attributes.attributes.headAdScript;
        } else if (trait.attributes.id == "bodyAdScript") {
          value = component.attributes.attributes.bodyAdScript;
        } else {
          value = component.attributes.attributes.bodyAdMobileScript;
        }
        elInput.querySelector("#" + trait.attributes.id).value = value;
      } else {
        elInput.querySelector("#" + trait.attributes.id).value = component.attributes.attributes.viewAllUrl;
      }
    },

    createInput({ trait, component }) {
      const elem = document.createElement("div");

      if (this.model.target.attributes.type && this.model.target.attributes.type === 'Breaking News'
        && !(trait.attributes.id == "headAdScript"
          || trait.attributes.id == "bodyAdScript"
          || trait.attributes.id == "bodyAdMobileScript"
          || trait.attributes.id == "headAdScript2"
          || trait.attributes.id == "bodyAdScript2"
          || trait.attributes.id == "bodyAdMobileScript2")) {
        elem.innerHTML = `<input id="${trait.attributes.id}" type="text" placeholder="Enter Url here..." value="${component.attributes.mainHeadingHref}"/>`;
      } else if (this.model.target.attributes.type
        && (this.model.target.attributes.type === 'Hero Layout'
          || this.model.target.attributes.type === 'Grid View'
          || this.model.target.attributes.type === 'Carousel Card Block'
          || this.model.target.attributes.type === 'List View'
          || this.model.target.attributes.type === 'Similar Stories'
          || this.model.target.attributes.type === 'Breaking News'
          || this.model.target.attributes.type === 'Article Show'
          || this.model.target.attributes.type === 'Article Show Amp'
          || this.model.target.attributes.type === 'Hero Layout2'
          || this.model.target.attributes.type === 'Grid View2'
          || this.model.target.attributes.type === 'PhotoList Two'
          || this.model.target.attributes.type === 'Related News'
          || this.model.target.attributes.type === 'Quick View Two'
          || this.model.target.attributes.type === 'RhsHome Two'
          || this.model.target.attributes.type === 'RhsHome Other Two'
          || this.model.target.attributes.type === 'WebStories Two'
          || this.model.target.attributes.type === 'Article Show Two Amp'
          || this.model.target.attributes.type === 'Video Show Two Amp'
          || this.model.target.attributes.type === 'Photo Show Two Amp'
          || this.model.target.attributes.type === 'List View Two'
          || this.model.target.attributes.type === 'Article Show Two'
          || this.model.target.attributes.type === 'MediaList Two'
          || this.model.target.attributes.type === 'Slide Show Two'
          || this.model.target.attributes.type === 'WebStoriesList Two'
          || this.model.target.attributes.type === 'Podcast Two'
          || this.model.target.attributes.type === 'GridView Two'
          || this.model.target.attributes.type === 'List View Three'
          || this.model.target.attributes.type === 'Article Three'
          || this.model.target.attributes.type === 'PhotoList Three'
          || this.model.target.attributes.type === 'Quick View Three'
          || this.model.target.attributes.type === 'RhsHome Three'
          || this.model.target.attributes.type === 'RhsHome Other Three'
          || this.model.target.attributes.type === 'WebStories Three'
          || this.model.target.attributes.type === 'Article Show Three Amp'
          || this.model.target.attributes.type === 'Video Show Three Amp'
          || this.model.target.attributes.type === 'Photo Show Three Amp'
          || this.model.target.attributes.type === 'List View Three'
          || this.model.target.attributes.type === 'Article Show Three'
          || this.model.target.attributes.type === 'MediaList Three'
          || this.model.target.attributes.type === 'Slide Show Three'
          || this.model.target.attributes.type === 'WebStoriesList Three'
          || this.model.target.attributes.type === 'Podcast Three'
          || this.model.target.attributes.type === 'GridView Three'
        )
        && trait.attributes.id != "viewAllUrl") {
        let script = "";
        if (trait.attributes.id == "headAdScript") {
          script = component.attributes.attributes.headAdScript
        } else if (trait.attributes.id == "bodyAdScript") {
          script = component.attributes.attributes.bodyAdScript
        } else if (trait.attributes.id == "bodyAdMobileScript") {
          script = component.attributes.attributes.bodyAdMobileScript;
        } else if (trait.attributes.id == "headAdScript2") {
          script = component.attributes.attributes.headAdScript2;
        } else if (trait.attributes.id == "bodyAdScript2") {
          script = component.attributes.attributes.bodyAdScript2;
        } else if (trait.attributes.id == "bodyAdMobileScript2") {
          script = component.attributes.attributes.bodyAdMobileScript2;
        }
        //elem.innerHTML = `<input id="${trait.attributes.id}" type="text" placeholder="Enter Url here..." value="${encodeURIComponent(script)}"/>`;
        elem.innerHTML = `<textarea id="${trait.attributes.id}" placeholder="Enter script here..." value="${encodeURIComponent(script)}" rows="4" cols="50"/>`;
      } else {
        elem.innerHTML = `<input id="${trait.attributes.id}" type="text" placeholder="Enter Url here..." value="${component.attributes.attributes.viewAllUrl}"/>`;
      }

      const inputsUrl = elem.querySelector("#" + trait.attributes.id);
      inputsUrl.addEventListener("change", (ev) => {
        let value = ev.target.value.trim();
        // if (!isValidUrl(value)) {
        //   toast.error("URL is not correct");
        //   this.model.attributes.placeholder = "Enter Url here...";
        //   ev.target.value  = '';
        // } else {
        //   this.model.setValue(value);
        // }
        this.model.setValue(value);
        ev.stopPropagation();
      });
      return elem;
    },
  });

  editor.TraitManager.addType("custom-toggle", {
    // Expects as return a simple HTML string or an HTML element

    createInput({ trait }) {
      // Here we can decide to use properties from the trait
      // Create a new element container and add some content
      const el = document.createElement("div");
      el.innerHTML = `
            <label class="switch">
              <input type="checkbox" ${trait.attributes?.isNotChecked() ? "" : "checked"}>
              <span class="slider"></span>
          </label>
        `;

      let component = this.getComponent();

      if (trait.attributes.showViewURLField && !trait.attributes.isNotChecked()) {
        setTimeout(() => {
          component.addTrait({ type: 'custom-urlarea', label: 'View Url', name: 'viewAllUrl' }, { at: 4 })
        })
      } else if (trait.attributes.showAdSlotField && !trait.attributes.isNotChecked()) {
        setTimeout(() => {
          if (trait.target.attributes.type === "Article Show" || trait.target.attributes.type === "Article Show Two") {
            component.addTrait({ type: 'custom-radio-buttons', label: 'Ad Size', name: 'adSize', }, { at: trait.collection.models.length - 1 })
          }
          component.addTrait({
            type: 'custom-select',
            label: 'Ad network',
            name: 'adNetwork',
            options: [
              { label: 'Google Ad', value: "google" },
              { label: 'Colombia Ad', value: "can" },
            ]
          }, { at: trait.collection.models.length - 1 })
          component.addTrait({ type: 'custom-urlarea', label: 'Header Script', name: 'headAdScript', }, { at: trait.collection.models.length - 1 })
          component.addTrait({ type: 'custom-urlarea', label: 'Body Script', name: 'bodyAdScript', }, { at: trait.collection.models.length - 1 })
          component.addTrait({ type: 'custom-urlarea', label: 'Body Script (Mobile)', name: 'bodyAdMobileScript' }, { at: trait.collection.models.length - 1 })
        })
      } else if (trait.attributes.showAdSlotField2 && !trait.attributes.isNotChecked()) {
        setTimeout(() => {
          if (trait.target.attributes.type === "Article Show") {
            component.addTrait({ type: 'custom-radio-buttons', label: 'Ad Size', name: 'adSize2', }, { at: trait.collection.models.length - 1 })
          }
          component.addTrait({
            type: 'custom-select',
            label: 'Ad network',
            name: 'adNetwork2',
            options: [
              { label: 'Google Ad', value: "google" },
              { label: 'Colombia Ad', value: "can" },
            ]
          }, { at: trait.collection.models.length - 1 })
          component.addTrait({ type: 'custom-urlarea', label: 'Header Script', name: 'headAdScript2', }, { at: trait.collection.models.length - 1 })
          component.addTrait({ type: 'custom-urlarea', label: 'Body Script', name: 'bodyAdScript2', }, { at: trait.collection.models.length - 1 })
          component.addTrait({ type: 'custom-urlarea', label: 'Body Script (Mobile)', name: 'bodyAdMobileScript2' }, { at: trait.collection.models.length - 1 })
        })
      }

      //Let's make our content interactive
      const inputsUrl = el.querySelector(".slider");
      if (inputsUrl) {
        inputsUrl.addEventListener("click", (ev) => {
          // console.log("this", this)
          // console.log("click fired", ev);
          let value = this.model.get("value");
          this.model.setValue(!value);

          if (trait.attributes.showViewURLField) {
            if (!value) {
              component.addTrait({ type: 'custom-urlarea', label: 'View Url', name: 'viewAllUrl' }, { at: 4 })
            } else {
              component.removeTrait("viewAllUrl")
            }
          } else if (trait.attributes.showAdSlotField) {
            if (!value) {
              if (trait.target.attributes.type === "Article Show" || trait.target.attributes.type === "Article Show Two") {
                component.addTrait({ type: 'custom-radio-buttons', label: 'Ad Size', name: 'adSize', }, { at: trait.collection.models.length - 1 })
              }
              component.addTrait({
                type: 'custom-select',
                label: 'Ad network',
                name: 'adNetwork',
                options: [
                  { label: 'Google Ad', value: "google" },
                  { label: 'Colombia Ad', value: "can" },
                ]
              }, { at: trait.collection.models.length - 1 })
              component.addTrait({ type: 'custom-urlarea', label: 'Header Script', name: 'headAdScript' }, { at: trait.collection.models.length - 1 })
              component.addTrait({ type: 'custom-urlarea', label: 'Body Script (Desktop)', name: 'bodyAdScript' }, { at: trait.collection.models.length - 1 })
              component.addTrait({ type: 'custom-urlarea', label: 'Body Script (Mobile)', name: 'bodyAdMobileScript' }, { at: trait.collection.models.length - 1 })
            } else {
              //setting value to blank in case of adslot removed
              component.traits.models
                .filter(trait => trait.attributes.name === 'adNetwork')
                .map(trait => trait.setValue('google'));
              component.traits.models
                .filter(trait => trait.attributes.name === 'headAdScript' || trait.attributes.name === 'bodyAdScript' || trait.attributes.name === 'bodyAdMobileScript' || trait.attributes.name === 'adSize')
                .map(trait => trait.setValue(''));
              component.removeTrait("adNetwork");
              component.removeTrait("headAdScript");
              component.removeTrait("bodyAdScript");
              component.removeTrait("bodyAdMobileScript");
              component.removeTrait("adSize");
            }
          } else if (trait.attributes.showAdSlotField2) {
            if (!value) {
              if (trait.target.attributes.type === "Article Show") {
                component.addTrait({ type: 'custom-radio-buttons', label: 'Ad Size', name: 'adSize2', }, { at: trait.collection.models.length - 1 })
              }
              component.addTrait({
                type: 'custom-select',
                label: 'Ad network',
                name: 'adNetwork2',
                options: [
                  { label: 'Google Ad', value: "google" },
                  { label: 'Colombia Ad', value: "can" },
                ]
              }, { at: trait.collection.models.length - 1 })
              component.addTrait({ type: 'custom-urlarea', label: 'Header Script', name: 'headAdScript2' }, { at: trait.collection.models.length - 1 })
              component.addTrait({ type: 'custom-urlarea', label: 'Body Script (Desktop)', name: 'bodyAdScript2' }, { at: trait.collection.models.length - 1 })
              component.addTrait({ type: 'custom-urlarea', label: 'Body Script (Mobile)', name: 'bodyAdMobileScript2' }, { at: trait.collection.models.length - 1 })
            } else {
              //setting value to blank in case of adslot removed
              component.traits.models
                .filter(trait => trait.attributes.name === 'adNetwork2')
                .map(trait => trait.setValue('google'));
              component.traits.models
                .filter(trait => trait.attributes.name === 'headAdScript2' || trait.attributes.name === 'bodyAdScript2' || trait.attributes.name === 'bodyAdMobileScript2' || trait.attributes.name === 'adSize2')
                .map(trait => trait.setValue(''));
              component.removeTrait("adNetwork2");
              component.removeTrait("headAdScript2");
              component.removeTrait("bodyAdScript2");
              component.removeTrait("bodyAdMobileScript2");
              component.removeTrait("adSize2");
            }
          }
          ev.stopPropagation();

        });
      }

      return el;
    },
  });

  editor.TraitManager.addType("custom-radio-buttons", {
    createInput({ }) {
      return `
          <div>
            <div data-gjs-radio-buttons></div>
          </div>
        `;
    },

    // Function to populate the trait with data
    onUpdate(el, data) {
      const { name, label, options, value } = this.model.attributes;
      let selectedVal = value || "1";
      const onChange = (event) => {
        const selectedValue = event.target.value;
        this.model.setValue(selectedValue);
      };

      const radio = (
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedVal}
          onChange={onChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="728 X 90" />
          <FormControlLabel value="2" control={<Radio />} label="300 X 250" />
        </RadioGroup>
      );

      let elm = this.model.view.el;
      let selectedElm = elm.querySelectorAll(".gjs-field-wrp--custom-radio-buttons");

      el.component.setAttributes({ ...el.component.attributes.attributes, adSize: selectedVal });
      ReactDOM.render(radio, selectedElm[0]);
    },
  });

  editor.TraitManager.addType("custom-select", {
    // Function to render the UI for the trait
    createInput({ }) {
      return `
          <div>
            <div data-gjs-custom-select></div>
          </div>
        `;
    },

    // Function to populate the trait with data
    onUpdate(el, data) {
      let { name, label, options, value } = this.model.attributes;
      let selectedVal = value || (this.target.attributes.seperator || this.target.attributes.type1);
      const onChange = (event) => {
        const selectedValue = event.target.value;
        this.model.setValue(selectedValue);
      };
      let setValueFlag = false;
      if (el.component.attributes.type !== undefined && (el.component.attributes.type === "Ad Scripts" || el.component.attributes.type === "Ad Scripts Two")) {
        if (el.component.attributes.attributes.compWidth > 300 && el.component.attributes.attributes.compWidth < 400) {
          options = [{ label: '300*100', value: "300*100" },
          { label: '300*250', value: "300*250" }]
          setValueFlag = true;
        } else if (el.component.attributes.attributes.compWidth > 970) {
          options = [{ label: '970*90', value: "970*90" }]
          selectedVal = '970*90'
          setValueFlag = true;
        }
      }

      const select = (
        <Select value={selectedVal} onChange={onChange} size="small">
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      );

      let elm = this.model.view.el;
      let selectedElm = elm.querySelectorAll(".gjs-field-wrp--custom-select");

      ReactDOM.render(select, selectedElm[0]);

      if (setValueFlag) {
        this.model.setValue(selectedVal);
      }
    },
  });

  editor.TraitManager.addType("data-mapping-trait", {
    noLabel: true,

    // Return a simple HTML string or an HTML element
    createInput({ trait }) {
      const mainContainer = document.createElement("div");
      mainContainer.id = "accordian-testing-123";
      return mainContainer;
    },
    onUpdate({ elInput, component }) {
      let { options, value } = this.model.attributes;
      //let updatedValue = {...value};
      if (
        typeof component.attributes.attributes.dataMappingTrait !=
        "undefined" &&
        (component.changed?.status == "selected" ||
          Object.keys(component.changed).length === 0)
      ) {
        //initial value
        value = { ...component.attributes.attributes.dataMappingTrait };
        this.model.attributes.value = { ...component.attributes.attributes.dataMappingTrait };
      }
      let onChange,
        finalValue,
        label,
        id = "";
      const onTextChange = (event) => {
        const valueObj = this.model.attributes.value || [];
        if (valueObj == "") { return false }
        this.model.setValue({
          ...valueObj,
          selectContainer: event.target.value,
        });
      };
      const handleChange = (event) => {
        const valueObj = this.model.attributes.value;
        if (valueObj == "") { return false }
        this.model.setValue({
          ...valueObj,
          //selectContainer: "",
          componentBinder: event.target.value,
        });
        if (event.target.value == "pathquery") {
          const mappings = {
            type: "pathquery",
            requestKey: 'msid',
            value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });',
          };
          component.attributes.attributes.mappings = mappings;
        } else if (event.target.value == "sibling") {
          const mappings = {
            type: "siblings",
            requestKey: value.attributeType,
            value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = ' + value.attributeType + ' });',
          };
          component.attributes.attributes.mappings = mappings;
        } else {
          delete component.attributes.attributes.mappings;
        }
      };
      const onChangeParentComponent = (event) => {
        const valueObj = this.model.attributes.value;
        if (
          typeof event.target.value != "undefined" &&
          event.target.value != null
        ) {
          this.model.setValue({
            ...valueObj,
            selectContainer: "",
            parentComponent: event.target.value,
            attributeType: ""
          });
          component.attributes.attributes.parentComponent = event.target.value.split('|')[0];
        }
      };
      //URL FIELD
      // const onChangeUrlField = (event) => {
      //   const valueObj = this.model.attributes.value;
      //   this.model.setValue({
      //     ...valueObj,
      //     selectContainer: "",
      //     urlfield: event.target.value,
      //   });
      //   if (component.attributes.attributes.mappings.type == "pathquery") {
      //     component.attributes.attributes.mappings.requestKey =
      //       event.target.value;
      //     component.attributes.attributes.mappings.value =
      //       'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.' + event.target.value + ' = ' + event.target.value + ' });';
      //   }
      // };
      //ATTRIBUTE TYPE
      const onChangeAttributeType = (event) => {
        const valueObj = this.model.attributes.value;
        if (typeof event.target.value != "undefined" && event.target.value != null) {
          this.model.setValue({
            ...valueObj,
            selectContainer: "",
            attributeType: event.target.value,
          });
          const mappings = {
            type: "siblings",
            requestKey: event.target.value,
            value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = ' + event.target.value + ' });',
          };
          component.attributes.attributes.mappings = mappings;
          getParentComponentsInView1().forEach((op) => {
            if (op.attributes.type == "List View" ||
              op.attributes.type == "Article Show" ||
              op.attributes.type == "Video Show" ||
              op.attributes.type == "Slide Show" ||
              op.attributes.type == "Author Show Two" ||
              op.attributes.type == "Author Show" ||
              op.attributes.type == "Article Show Two" ||
              op.attributes.type == "List View Two" ||
              op.attributes.type == "Video Show Two" ||
              op.attributes.type == "Slide Show Two") {
              op.attributes.attributes.exportedKeys = { ...op.attributes.attributes.exportedKeys, [event.target.value]: null }
            }
          });
        }
      };
      let top100Films = [];
      const selectContainerOnChange = (event) => {
        const valueObj = this.model.attributes.value;
        if (event.currentTarget.id.includes('autocomplete-select-container-id')) {
          const msidToAdd = event.currentTarget.children[0].getAttribute('value');
          if (!valueObj.selectContainer.includes(msidToAdd)) {
            if (valueObj.selectContainer === '') {
              this.model.setValue({
                ...valueObj,
                selectContainer: msidToAdd
              });
            }
            else if (valueObj.mappingType === 'cp' || valueObj.mappingType === 'ch') {
              this.model.setValue({
                ...valueObj,
                selectContainer: valueObj.selectContainer + "," + msidToAdd
              });
            }
            tempTextFieldValue = '';
          }
        } else if (event.currentTarget.getAttribute("data-testid") === "CancelIcon") {
          const msidToRemove = event.currentTarget.parentElement.children[0].innerHTML;
          const newValue = removeSpecificText(valueObj.selectContainer, msidToRemove);
          this.model.setValue({
            ...valueObj,
            selectContainer: newValue
          });
        }

        function removeSpecificText(commaSeparatedString, textToRemove) {
          const array = commaSeparatedString.split(",");
          const filteredArray = array.filter(item => item !== textToRemove && item !== "");
          return filteredArray.join(",");
        }

      };
      let pageType = '';
      if (typeof getSessionObject("metaData") != 'undefined') {
        pageType = JSON.parse(getSessionObject("metaData"))?.pageType;
      }

      const selectContainerInnerOnChange = (event) => {
        const valueObj = this.model.attributes.value;

        if (event.target.value.length >= 3) {
          let url = PAGE_BUILDER_ENDPOINTS.DENMARK_SEARCH_NEWSROOM + '?hostId=' + getSessionObject('hostId') + '&searchTerms=' + event.target.value;
          const config = {
            url: url,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          };

          httpMiddleware(config)
            .then((response) => {
              if (response.status === 200) {
                const result = response.data;
                if (result.numFound > 0) {
                  top100Films = [];
                  result.response.forEach((item) => {
                    if (item.status == 2) {
                      item.status = 'Active'
                    } else {
                      item.status = 'In-active'
                    }
                    const obj = {
                      name: item.category,
                      msid: item.msid,
                      path: item.path,
                      status: item.status
                    }
                    top100Films.push(obj);
                  })
                  this.model.setValue({
                    ...valueObj,
                    denmarkSearchedItems: top100Films
                  });
                  this.onUpdate({
                    elInput: elInput,
                    component: component
                  });
                } else {
                  this.model.setValue({
                    ...valueObj,
                    denmarkSearchedItems: []
                  });
                  top100Films = [];
                }
              } else {
                throw new Error('Request failed with status: ' + response.status);
              }
            })
            .catch((error) => {
              console.error("API Error:", error);
              return null;
            });
        } else {
          this.model.setValue({
            ...valueObj,
            denmarkSearchedItems: []
          });
          top100Films = [];
        }
        tempTextFieldValue = event.target.value;
      }

      function validateInput(inputString) {
        const regex = new RegExp('^[0-9,]+$');
        return regex.test(inputString);
      }

      let selectAll = false;
      const handleSelectAll = (event) => {
        const valueObj = this.model.attributes.value;
        if (typeof event.target.checked !== 'undefined') {
          if (event.target.checked) {
            const selectedItems = ["article", "video", "slideshow", "audio", "immersivestory","quiz","survey"]
            this.model.setValue({
              ...valueObj,
              storyType: selectedItems,
              isChecked: true
            });
          } else {
            this.model.setValue({
              ...valueObj,
              storyType: [],
              isChecked: false
            });
          }
        } else {
          const checkbox = document.querySelector("#selectAll-checkbox");
          if (checkbox) {
            const changeEvent = new Event('change', { bubbles: true });
            checkbox.dispatchEvent(changeEvent);
          }
          // if (event.target.parentElement.parentElement.querySelector('input[type="checkbox"]').checked) {
          //   const selectedItems = ["article", "video", "slideshow", "audio", "immersivestory"]
          //   this.model.setValue({
          //     ...valueObj,
          //     storyType: selectedItems,
          //     isChecked: true
          //   });
          //   //event.target.parentElement.parentElement.querySelector('input[type="checkbox"]').checked = false;
          // } else {
          //   this.model.setValue({
          //     ...valueObj,
          //     storyType: [],
          //     isChecked: false
          //   });
          //   //event.target.parentElement.parentElement.querySelector('input[type="checkbox"]').checked = true;
          // }
        }

      };

      const textObj1 = (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-data-mapping"
            id="panel-data-mapping"
          >
            <Typography>Data Mapping</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ToggleButtonGroup
              color="primary"
              value={value.componentBinder}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              size="small"
              style={{ height: "32px", marginBottom: "20px" }}
            >
              <ToggleButton value="static" style={{ textTransform: "none" }}>Static</ToggleButton>
              {(pageType != 'HomePage' && pageType != 'StaticPage' && pageType != 'ErrorPage') && <ToggleButton value="pathquery" style={{ textTransform: "none" }}>URL Driven</ToggleButton>}
              {(pageType != 'HomePage' && pageType != 'StaticPage' && pageType != 'ErrorPage') && !PARENT_COMPONENTS_DATA_MAPPING.includes(
                component.attributes.type
              ) && <ToggleButton value="sibling" style={{ textTransform: "none" }}>Association</ToggleButton>}
            </ToggleButtonGroup>

            {options.map((option, index) => {
              if (index == 0) {
                onChange = (event, element) => {
                  const valueObj = this.model.attributes.value;
                  this.model.setValue({
                    ...valueObj,
                    mappingType: event.target.value,
                    selectContainer: ''
                  });
                };
                id = "data-mapping-type";
                label = "Mapping Type";
                //remoing the not needed mapping types
                if (value.componentBinder == 'static') {
                  option = option.filter(op => op.value !== 'sh' && op.value !== 'ssh' && op.value !== 'auth-list' && op.value !== 'orian-search');
                } else if (value.componentBinder == 'pathquery') {
                  option = option.filter(op => op.value !== 'cp' && op.value !== 'ch' && op.value !== 'sh' && op.value !== 'ssh' && op.value !== 'orian-search');
                } else if (value.componentBinder == 'sibling') {
                  option = option.filter(op => op.value !== 'cp' && op.value !== 'ch' && op.value !== 'sh' && op.value !== 'ssh' && op.value !== 'knowledge-search');
                }

                finalValue = value.mappingType;
              } else if (index == 1) {
                onChange = (event, element) => {
                  const valueObj = this.model.attributes.value;
                  if (typeof event.target.value === 'object' && event.target.value.includes("all")) {
                    event.target.value = ["article", "video", "slideshow", "audio", "immersivestory","quiz","survey"];
                  }
                  event.target.value = event.target.value.filter((item) => item !== 'select-all');
                  this.model.setValue({
                    ...valueObj,
                    storyType: event.target.value,
                  });
                };
                const valueObj = this.model.attributes.value;
                if (this.target.attributes.type === "Web Stories" || this.target.attributes.type === "Web Stories2" || this.target.attributes.type === "WebStoriesList Two" || this.target.attributes.type === "WebStoriesList Three" || this.target.attributes.type === "WebStories Two" || this.target.attributes.type === "WebStories Three" || this.target.attributes.type === "Home Web Stories") {
                  option = option.filter(op => op.value !== 'article' && op.value !== 'video' && op.value !== 'slideshow' && op.value !== 'audio' && op.value !== 'immersivestory');
                  value = { ...value, storyType: ["webstory"] };
                } else if (this.target.attributes.type === "Circular Card Carousel") {
                } else {
                  option = option.filter(op => op.value !== 'webstory');
                }
                id = "data-mapping-story-type";
                label = "Story Type";
                finalValue = value.storyType.filter((item) => item !== 'select-all');
              } else if (index == 2) {
                if (value.mappingType == "p") {
                  option = [{ label: "Rank", value: "rank" }];
                  if (option.filter((op) => op.value == value.sortType).length == 0) {
                    value = { ...value, sortType: option[0].value };
                  }
                } else if (
                  value.mappingType == "ch" ||
                  value.mappingType == "cp" ||
                  value.mappingType == "lh"
                ) {
                  option = [
                    { label: "Published Time", value: "date" },
                    { label: "Updated Time", value: "updatedate" },
                  ];
                  if (
                    option.filter((op) => op.value == value.sortType).length ==
                    0
                  ) {
                    value = { ...value, sortType: option[0].value };
                  }
                } else
                  option = [
                    { label: "Rank", value: "rank" },
                    { label: "Published Time", value: "date" },
                    { label: "Updated Time", value: "updatedate" },
                  ];

                onChange = (event, element) => {
                  const valueObj = this.model.attributes.value;
                  this.model.setValue({
                    ...valueObj,
                    sortType: event.target.value,
                  });
                };
                id = "data-mapping-sort-type";
                label = "Sort By";
                finalValue = value.sortType;
              } else if (index == 3) {
                if (
                  value.mappingType == "h" ||
                  value.mappingType == "ch" ||
                  value.mappingType == "lh"
                )
                  option = [{ label: "Descending", value: "dsc" }];

                onChange = (event, element) => {
                  const valueObj = this.model.attributes.value;
                  this.model.setValue({
                    ...valueObj,
                    sortOrder: event.target.value,
                  });
                };
                id = "data-mapping-sort-order";
                label = "Sort Order";
                finalValue = value.sortOrder;
              }
              const parentVal = value.parentComponent;
              let firstPart;
              if (parentVal && parentVal.includes('|')) {
                // Split the string and get the first part
                firstPart = parentVal.split('|')[0];
              } else {
                // Handle the case where | is not present
                firstPart = parentVal;
              }
              return (
                <>
                  <FormHelperText>{label}</FormHelperText>
                  <Select
                    multiple={index === 1 ? true : undefined}
                    key={index}
                    id={id}
                    value={finalValue}
                    onChange={onChange}
                    size="small"
                    fullWidth
                    style={{ marginBottom: "20px", height: "32px" }}
                    renderValue={index === 1 &&
                      ((selected) => {
                        return selected.length === option.length ? 'All Selected' :
                          (() => {
                            const selectedLabels = selected.map((value) => {
                              const selectedOption = option.find((op) => op.value === value);
                              return selectedOption ? selectedOption.label : value;
                            }).join(', ');
                            return selectedLabels.length > 22 ? selectedLabels.substring(0, 22) + '...' : selectedLabels;
                          })();
                      })
                    }
                  >
                    {/* {index === 1 &&
                      <MenuItem key="select-all" value="select-all" onClick={handleSelectAll}>
                        <Checkbox id="selectAll-checkbox" checked={value.isChecked} onChange={handleSelectAll} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      // <label>
                      //   <Checkbox checked={value.isChecked} onClick={handleSelectAll} />
                      //   Select all
                      // </label>
                    } */}
                    {index === 1 && option.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        <Checkbox checked={finalValue.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                    {index !== 1 && option.map((op, index) => (
                      <MenuItem key={index} value={op.value}>
                        {op.label}
                      </MenuItem>
                    ))}
                  </Select>

                  {value.componentBinder == "static" && index == 0 && value.mappingType !== 'keyword-search' && value.mappingType !== 'knowledge-search' && (
                    <>
                      <FormHelperText>Search Section</FormHelperText>
                      {/* <TextField
                        id="select-container-element"
                        value={value.selectContainer}
                        variant="outlined"
                        fullWidth
                        type="number"
                        onChange={onTextChange}
                        style={{ marginBottom: "20px" }}
                      /> */}
                      <Autocomplete
                        multiple
                        id="autocomplete-select-container-id"
                        options={value.denmarkSearchedItems ? value.denmarkSearchedItems : []}
                        getOptionLabel={(option) => {
                          if (typeof option.msid === 'undefined') {
                            return Array.from(option.split(","))
                          }
                          return (
                            <div value={option.msid}>
                              <div class="autocomplete-name">{option.name}</div>
                              <div class="autocomplete-msid-url">{option.msid} - {option.path}</div>
                              <div class="autocomplete-status">{option.status}</div>
                            </div>
                          );
                        }
                        }
                        value={value.selectContainer != '' && validateInput(value.selectContainer) ? (value.selectContainer.includes(',') ? Array.from(value.selectContainer.split(",")) : [value.selectContainer]) : []}
                        filterSelectedOptions
                        onChange={selectContainerOnChange}
                        renderInput={(params) => {
                          params.inputProps.value = tempTextFieldValue
                          if (params.inputProps.ref.current != null) {
                            if (value.mappingType != 'cp' && value.mappingType != 'ch' && value.selectContainer != '') {
                              params.inputProps.ref.current.style.display = 'none';
                            } else {
                              params.inputProps.ref.current.style.display = 'block';
                            }
                          }

                          return (
                            <TextField
                              {...params}
                              placeholder="Add a container"
                              onChange={selectContainerInnerOnChange}
                            />
                          )
                        }
                        }
                        style={{ marginBottom: "20px" }}
                      />
                    </>
                  )}
                  {value.componentBinder == "static" && index == 0 && (value.mappingType === 'keyword-search' || value.mappingType === 'knowledge-search') && (
                    <>
                      <FormHelperText>Search Keyword</FormHelperText>
                      <TextField
                        id="select-container-element"
                        value={value.selectContainer}
                        variant="outlined"
                        fullWidth
                        onChange={onTextChange}
                        style={{ marginBottom: "20px" }}
                      />
                    </>
                  )}

                  {/* {value.componentBinder == "pathquery" && index == 0 && (
                    <>
                      <FormHelperText>URL field</FormHelperText>
                      <Select
                        value={value.urlfield}
                        onChange={onChangeUrlField}
                        size="small"
                        fullWidth
                        style={{ marginBottom: "20px", height: "32px" }}
                      >
                        <MenuItem value="msid">Section/Author</MenuItem>
                        <MenuItem value="keyword">Keyword/Search Term</MenuItem>
                      </Select>
                    </>
                  )} */}

                  {value.componentBinder == "sibling" && index == 0 && (
                    <>
                      <FormHelperText>Parent Component</FormHelperText>
                      <Select
                        value={
                          value.parentComponent != null ? value.parentComponent : null
                        }
                        onChange={onChangeParentComponent}
                        size="small"
                        fullWidth
                        style={{ marginBottom: "20px", height: "32px" }}
                        disabled={getParentComponentsInView2().length === 0}
                      >
                        {getParentComponentsInView3().map((op, index) => (
                          <MenuItem key={index} value={op.attributes.type + (typeof op.attributes.title != "undefined" ? "|" + op.attributes.title : "")}>
                            {op.attributes.type + (typeof op.attributes.title != "undefined" ? "|" + op.attributes.title : "")}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Attribute Type</FormHelperText>
                      <Select
                        value={value.attributeType}
                        onChange={onChangeAttributeType}
                        size="small"
                        fullWidth
                        style={{ marginBottom: "20px", height: "32px" }}
                        disabled={firstPart === null}
                      >

                        {(firstPart === 'Article Show' || firstPart === 'Video Show' || firstPart === 'Slide Show' || firstPart === 'List View' || firstPart === 'Article Show Two' || firstPart === 'Video Show Two' || firstPart === 'Slide Show Two' || firstPart === 'List View Two') &&
                          <MenuItem value='pid'>
                            Parent Section
                          </MenuItem>}
                        {(firstPart === 'Article Show' || firstPart === 'Video Show' || firstPart === 'Article Show Two' || firstPart === 'Video Show Two') &&
                          <MenuItem value='sectionid'>
                            Article Id
                          </MenuItem>}
                        {(firstPart === 'Article Show' || firstPart === 'Video Show' || firstPart === 'Slide Show' || firstPart === 'Author Show' || firstPart === 'Author Show Two' || firstPart === 'Article Show Two' || firstPart === 'Video Show Two' || firstPart === 'Slide Show Two') &&
                          <MenuItem value='authorid'>
                            Author's Collection
                          </MenuItem>}
                        {(firstPart === 'Article Show' || firstPart === 'Video Show' || firstPart === 'Slide Show' || firstPart === 'Article Show Two' || firstPart === 'Video Show Two' || firstPart === 'Slide Show Two') &&
                          <MenuItem value='tags'>
                            Keywords / Tags Collection
                          </MenuItem>}
                      </Select>
                    </>
                  )}
                </>
              );
            })}
          </AccordionDetails>
        </Accordion>
      );

      ReactDOM.render(
        textObj1,
        this.model.view.el.querySelector("#accordian-testing-123")
      );

      let default_msid = null;
      let defaultIds = localStorage.getItem("defaultIds");
      let reqHostId = getSessionObject('hostId');
      let defaultSystemHostId = localStorage.getItem("defaultSystemHostId");

      if (typeof value.selectContainer === 'string' && value.selectContainer.trim() === "") {
        default_msid = component.attributes.attributes.ENDPOINTS.find((e) => e.name === "MAIN").queryParams.msid;

        if (value.mappingType == "knowledge-search") {
          let defaultKeywords = localStorage.getItem("defaultKeywords");
          if (defaultKeywords) {
            default_msid = defaultKeywords;
            reqHostId = defaultSystemHostId;
          }
        }
        if (value.mappingType == "keyword-search") {
          let defaultKeywords = localStorage.getItem("defaultKeywords");
          if (defaultKeywords) {
            default_msid = defaultKeywords;
            reqHostId = defaultSystemHostId;
          }
        }
        if (value.mappingType == "h" || value.mappingType == "ch" || value.mappingType == "lh") {
          let defaultListingMsid = localStorage.getItem("defaultListingMsid");
          if (defaultListingMsid) {
            default_msid = defaultListingMsid;
            reqHostId = defaultSystemHostId;
          }
        }
        if (value.mappingType == "p" || value.mappingType == "cp") {
          let defaultPriortyListMsid = localStorage.getItem("defaultPriortyListMsid");
          if (defaultPriortyListMsid) {
            default_msid = defaultPriortyListMsid;
            reqHostId = defaultSystemHostId;
          }
        }
        if (value.mappingType == "auth-list") {
          let defaultAuthorID = localStorage.getItem("defaultAuthorID");
          if (defaultAuthorID) {
            default_msid = defaultAuthorID;
            reqHostId = defaultSystemHostId;
          }
        }
        if (value.mappingType == "orian-search") {
          let defaultArticleMsid = localStorage.getItem("defaultArticleMsid");
          if (defaultArticleMsid) {
            default_msid = defaultArticleMsid;
            reqHostId = defaultSystemHostId;
          }
        }

      } else {
        default_msid = value.selectContainer;
      }

      const ENDPOINTS = component.attributes.attributes.ENDPOINTS.map(
        (item) => {
          if (item.name === "MAIN") {

            // if ((!default_msid || default_msid == 'modi') && value.mappingType == "keyword-search" || value.mappingType == "knowledge-search") {
            //   let defaultKeywords = localStorage.getItem("defaultKeywords");
            //   if (defaultKeywords) {
            //     default_msid = defaultKeywords;
            //     reqHostId = defaultSystemHostId;
            //   }
            // }

            if (default_msid && defaultIds && defaultIds.includes(default_msid)) {
              reqHostId = defaultSystemHostId;
            }

            // Replace the value and add a new property
            return {
              ...component.attributes.attributes.ENDPOINTS.find(
                (e) => e.name === "MAIN"
              ),
              queryParams: {
                ...component.attributes.attributes.ENDPOINTS.find(
                  (e) => e.name === "MAIN"
                ).queryParams,
                apiType: value.mappingType,
                msid: default_msid,
                hostId: reqHostId,
                ct: value.storyType,
                st: value.sortType,
                so: value.sortOrder,
              },
            };
          }
          else if (item.name === "HERO_API") {

            // if ((!default_msid || default_msid == 'modi') && value.mappingType == "keyword-search" || value.mappingType == "knowledge-search") {
            //   let defaultKeywords = localStorage.getItem("defaultKeywords");
            //   if (defaultKeywords) {
            //     default_msid = defaultKeywords;
            //     reqHostId = defaultSystemHostId;
            //   }
            // }

            if (default_msid && defaultIds && defaultIds.includes(default_msid)) {
              reqHostId = defaultSystemHostId;
            }

            // Replace the value and add a new property
            return {
              ...component.attributes.attributes.ENDPOINTS.find(
                (e) => e.name === "HERO_API"
              ),
              queryParams: {
                ...component.attributes.attributes.ENDPOINTS.find(
                  (e) => e.name === "HERO_API"
                ).queryParams,
                apiType: value.mappingType,
                msid: default_msid,
                hostId: reqHostId,
                ct: value.storyType,
                st: value.sortType,
                so: value.sortOrder,
              },
            };
          }
          return item;
        }
      );
      component.addAttributes({ ENDPOINTS });
    },
  });

  editor.TraitManager.addType("accordian", {
    noLabel: true,

    // Return a simple HTML string or an HTML element
    createInput({ trait }) {
      // Create a new element container add some content
      const colorSetBlock = (
        <div class="gjs-sm-label" data-sm-label="" style={{ display: "block" }}>
          {colorSetOptionsList.map((option, colorSetId) => (
            <div key={colorSetId}
              class="gjs-sm-field"
              style={{ display: "flex", alignItems: "center", width: "fit-content", border: "0" }}
            >
              <input
                type="radio"
                id={colorSetId}
                name={option.colorSetName}
                value={JSON.stringify(option.colorSetValue)}
                style={{ WebkitAppearance: "radio", width: 20, marginRight: 10 }}
              />
              <div class="color-box-container">
                <div
                  style={{
                    backgroundColor: option.colorSetValue["--primary"],
                  }}
                  className="color-block"
                ></div>
                <div
                  style={{
                    backgroundColor: option.colorSetValue["--secondary"],
                  }}
                  className="color-block"
                ></div>
                <div
                  style={{
                    backgroundColor: option.colorSetValue["--tertiary"],
                  }}
                  className="color-block"
                ></div>
                <div
                  style={{
                    backgroundColor: option.colorSetValue["--textColor"],
                  }}
                  className="color-block"
                ></div>
              </div>
            </div>
          ))}
        </div>
      );

      const colorSetBlockToString = ReactDOMServer.renderToString(colorSetBlock);
      const colorArray = [colorSetBlockToString];
      let el = document.createElement("div");
      el.innerHTML = TypographyHTMLCode(colorArray);
      el.style.marginLeft = "-10px";
      el.style.marginRight = "-10px";
      el.style.background = "#F3F3F3";
      this.model.attributes.unit = 'init';
      return el;
    },
    //value getter
    onEvent({ elInput, component }) {
      const typo_array = ["h1", "h2", "h3", "h4", "h5", "h6", "p"];
      let attribute_object = {};
      let css_object = {};
      typo_array.forEach((element) => {
        attribute_object[`typography_${element}`] = {
          "font-family": elInput.querySelector(
            `#typography-${element}-font-family`
          ).value,
          "font-size": {
            value: elInput.querySelector(`#typography-${element}-font-size`)
              .value,
            unit: elInput.querySelector(`#typography-${element}-font-size-end`)
              .value,
          },
          "font-weight": elInput.querySelector(
            `#typography-${element}-font-weight`
          ).value,
          "letter-spacing": {
            value: elInput.querySelector(
              `#typography-${element}-character-spacing`
            ).value,
            unit: elInput.querySelector(
              `#typography-${element}-character-spacing-end`
            ).value,
          },
          "line-height": elInput.querySelector(
            `#typography-${element}-line-spacing`
          ).value,
          "text-transform": elInput.querySelector(
            `#typography-${element}-text-transform`
          ).value,
        };

        css_object[`${element}`] = {
          "font-family":
            elInput.querySelector(`#typography-${element}-font-family`).value +
            " ",
          "font-size":
            elInput.querySelector(`#typography-${element}-font-size`).value +
            elInput.querySelector(`#typography-${element}-font-size-end`)
              .value +
            " ",
          "font-weight":
            elInput.querySelector(`#typography-${element}-font-weight`).value +
            "",
          "letter-spacing":
            elInput.querySelector(`#typography-${element}-character-spacing`)
              .value +
            elInput.querySelector(
              `#typography-${element}-character-spacing-end`
            ).value +
            " ",
          "line-height":
            elInput.querySelector(`#typography-${element}-line-spacing`).value +
            " ",
          "text-transform":
            elInput.querySelector(`#typography-${element}-text-transform`)
              .value + " ",
        };
      });
      attribute_object["root"] = typeof Array.from(elInput.querySelectorAll('input[name="ColorSet"]')).filter((input) => input.checked)[0]?.value != "undefined" ? JSON.parse(Array.from(elInput.querySelectorAll('input[name="ColorSet"]')).filter((input) => input.checked)[0]?.value) : "";
      css_object[":root"] = typeof Array.from(elInput.querySelectorAll('input[name="ColorSet"]')).filter((input) => input.checked)[0]?.value != "undefined" ? JSON.parse(Array.from(elInput.querySelectorAll('input[name="ColorSet"]')).filter((input) => input.checked)[0]?.value) : "";
      //attribute_object["content_color_set_value"]=(typeof Array.from(elInput.querySelectorAll('input[name="contentColorSet"]')).filter(input => input.checked)[0]?.value!="undefined")?Array.from(elInput.querySelectorAll('input[name="themeColorSet"]')).filter(input => input.checked)[0]?.value:""
      //attribute_object["background_color_set_value"]=(typeof Array.from(elInput.querySelectorAll('input[name="backgroundColorSet"]')).filter(input => input.checked)[0]?.value!="undefined")?Array.from(elInput.querySelectorAll('input[name="themeColorSet"]')).filter(input => input.checked)[0]?.value:""

      attribute_object["date-time-format"] = elInput.querySelector("#date-time-format").value;
      attribute_object["published-or-updated"] = elInput.querySelector("#published-or-updated").value;
      //attribute_object["time_format"]=typeof Array.from(elInput.querySelectorAll("input[name='time-format']")).filter(input => input.checked)[0]?.value != "undefined"?Array.from(elInput.querySelectorAll("input[name='time-format']")).filter(input => input.checked)[0]?.value : "";

      if (elInput.querySelector("#globalHeaderScript").value.trim() != "") {
        attribute_object["globalHeaderScript"] = elInput.querySelector("#globalHeaderScript").value.trim();
        //appendScriptToHeadOrBody("header_script", elInput.querySelector("#globalHeaderScript").value.trim());
      }
      if (elInput.querySelector("#globalBodyScript").value.trim() != "") {
        attribute_object["globalBodyScript"] = elInput.querySelector("#globalBodyScript").value.trim();
        //appendScriptToHeadOrBody("body_script", elInput.querySelector("#globalBodyScript").value.trim());
      }

      // //attribute_object["time-zone"]=elInput.querySelector('#time-zone').value

      // attribute_object["breadcrumbs-seprator"]=elInput.querySelector('#breadcrumbs-seprator').value
      // attribute_object["anchor-text-for-homepage"]=elInput.querySelector('#anchor-text-for-homepage').value
      // attribute_object["prefix-for-search-result-page"]=elInput.querySelector('#prefix-for-search-result-page').value
      // attribute_object["anchor-text-for-404"]=elInput.querySelector('#anchor-text-for-404').value
      let pageMetaData = JSON.parse(getSessionObject("metaData"));
      const body = {
        themeId: pageMetaData.themeId,
        clientId: pageMetaData.clientId,
        websiteId: pageMetaData.websiteId,
        env: 1,
        css: JSON.stringify(css_object),
        json: JSON.stringify(attribute_object),
      };
      let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/updateCssJsonWrtThemeId";

      const config = {
        url: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
      };
      httpMiddleware(config)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error('Request failed with status: ' + response.status);
          }
        })
        .then((data) => {
          //console.log("API Response:", data);
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
      component.addAttributes({ css_object, attribute_object });
      this.model.setValue({ css_object, attribute_object });
      this.model.attributes.unit = 'oe';
    },
    //value setter
    onUpdate(elInput, component) {
      if (typeof getSessionObject("metaData") != "undefined" && JSON.parse(getSessionObject("metaData")) != null && this.model.attributes.unit == 'init') {
        let data = null;
        let pageMetaData = JSON.parse(getSessionObject("metaData"));
        let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/getThemeByClientAndWebsite?clientId=" + pageMetaData.clientId + "&websiteId=" + pageMetaData.websiteId + "&env=1";
        const config = {
          url: url,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        };

        httpMiddleware(config)
          .then((response) => {
            if (response.status === 200) {
              data = response.data;
              return response.data;
            } else {
              throw new Error('Request failed with status: ' + response.status);
            }
          })
          .then((data) => {
            //console.log("API Response:", data[0]);
            PopulateGlobalSettingsData(data[0], elInput);
            //passing the values to the Global Component
            if (typeof data[0].css !== 'undefined' && typeof data[0].json !== 'undefined') {
              const css_object = JSON.parse(data[0].css);
              const attribute_object = JSON.parse(data[0].json);
              elInput.component.addAttributes({ css_object, attribute_object });
              this.model.setValue({ css_object, attribute_object });
            }
            return data[0];
          })
          .catch((error) => {
            console.error("API Error:", error);
            return null;
          });
      }
      // ReactDOM.render(TypographyHTMLCode(themeColorOption), this.model.view.el);
    },
  });
};

const PopulateGlobalSettingsData = (data, el) => {
  if (typeof data.json !== "undefined" && JSON.parse(data.json) != null) {
    const jsonObj = JSON.parse(data.json);
    const typo_array = ["h1", "h2", "h3", "h4", "h5", "h6", "p"];

    typo_array.forEach((element) => {
      const fontSize = defaultFontSizes[element];
      const fontWeight = defaultFontWeight[element];
      el.elInput.querySelector(`#typography-${element}-font-family`).value = jsonObj[`typography_${element}`][`font-family`];
      el.elInput.querySelector(`#typography-${element}-font-size`).value = jsonObj[`typography_${element}`][`font-size`][`value`] == "" ? fontSize : jsonObj[`typography_${element}`][`font-size`][`value`];
      el.elInput.querySelector(`#typography-${element}-font-size-end`).value = jsonObj[`typography_${element}`][`font-size`][`unit`];
      el.elInput.querySelector(`#typography-${element}-font-weight`).value = jsonObj[`typography_${element}`][`font-weight`] == "" ? fontWeight : jsonObj[`typography_${element}`][`font-weight`];
      el.elInput.querySelector(`#typography-${element}-character-spacing`).value = jsonObj[`typography_${element}`][`letter-spacing`][`value`] == "" ? 0 : jsonObj[`typography_${element}`][`letter-spacing`][`value`];
      el.elInput.querySelector(`#typography-${element}-character-spacing-end`).value = jsonObj[`typography_${element}`][`letter-spacing`][`unit`];
      el.elInput.querySelector(`#typography-${element}-line-spacing`).value = jsonObj[`typography_${element}`][`line-height`];
      el.elInput.querySelector(`#typography-${element}-text-transform`).value = jsonObj[`typography_${element}`][`text-transform`];
    });

    if (jsonObj.root != null && jsonObj.root != "")
      if (Array.from(el.elInput.querySelectorAll('input[name="ColorSet"]')).length > 0)
        Array.from(el.elInput.querySelectorAll('input[name="ColorSet"]')).filter((input) => input.value == JSON.stringify(jsonObj.root))[0].checked = true;
      else {
        if (Array.from(el.elInput.querySelectorAll('input[name="ColorSet"]')).length > 0)
          Array.from(el.elInput.querySelectorAll('input[name="ColorSet"]'))[0].checked = true;
      }
    // if(cssObj.content_color_set_value!=null && cssObj.content_color_set_value!='')
    //     Array.from(el.elInput.querySelectorAll('input[name="contentColorSet"]')).filter(input => input.value == cssObj.content_color_set_value)[0].checked = true;
    // if(cssObj.background_color_set_value!=null && cssObj.background_color_set_value!='')
    //     Array.from(el.elInput.querySelectorAll('input[name="backgroudColorSet"]')).filter(input => input.value == cssObj.background_color_set_value)[0].checked = true;

    el.elInput.querySelector(`#date-time-format`).value = jsonObj[`date-time-format`];
    el.elInput.querySelector(`#published-or-updated`).value = jsonObj[`published-or-updated`];
    // if(jsonObj.time_format!=null && jsonObj.time_format!='')
    //   Array.from(el.elInput.querySelectorAll('input[name="time-format"]')).filter(input => input.value == jsonObj.time_format)[0].checked = true;

    //global header and body scripts
    if (jsonObj.globalHeaderScript != null && jsonObj.globalHeaderScript != "") {
      el.elInput.querySelector(`#globalHeaderScript`).value = jsonObj.globalHeaderScript;
      appendScriptToHeadOrBody("header_script", jsonObj.globalHeaderScript);
    }

    if (jsonObj.globalBodyScript != null && jsonObj.globalBodyScript != "") {
      el.elInput.querySelector(`#globalBodyScript`).value = jsonObj.globalBodyScript;
      appendScriptToHeadOrBody("body_script", jsonObj.globalBodyScript);
    }

    //el.elInput.querySelector(`#time-zone`).value = jsonObj[`time-zone`];

    // el.elInput.querySelector(`#breadcrumbs-seprator`).value = jsonObj[`breadcrumbs-seprator`];
    // el.elInput.querySelector(`#anchor-text-for-homepage`).value = jsonObj[`anchor-text-for-homepage`];
    // el.elInput.querySelector(`#prefix-for-search-result-page`).value = jsonObj[`prefix-for-search-result-page`];
    // el.elInput.querySelector(`#anchor-text-for-404`).value = jsonObj[`anchor-text-for-404`];
  }
};

const getParentComponentsInView1 = (params) => {
  const filteredChildComponents = [];
  if (window.gpEditor) {
    //add all from the parents
    //filteredChildComponents.push(window.gpEditor.getComponents().filter((op)=> PARENT_COMPONENTS_DATA_MAPPING.includes(op.attributes.type)))
    //add all from the childs
    window.gpEditor.getComponents().forEach((parentComponent) => {
      if (PARENT_COMPONENTS_DATA_MAPPING.includes(parentComponent.attributes.type)) {
        filteredChildComponents.push(parentComponent);
      }
      parentComponent.forEachChild((childComponent) => {
        if (PARENT_COMPONENTS_DATA_MAPPING.includes(childComponent.attributes.type)) {
          filteredChildComponents.push(childComponent);
        }
      });
    });
  }
  return filteredChildComponents;
};

const getParentComponentsInView2 = (params) => {
  const filteredChildComponents = [];
  if (window.gpEditor) {
    //add all from the parents
    //filteredChildComponents.push(window.gpEditor.getComponents().filter((op)=> PARENT_COMPONENTS_DATA_MAPPING.includes(op.attributes.type)))
    //add all from the childs
    window.gpEditor.getComponents().forEach((parentComponent) => {
      if (PARENT_COMPONENTS_DATA_MAPPING.includes(parentComponent.attributes.type)) {
        filteredChildComponents.push(parentComponent);
      }
      parentComponent.forEachChild((childComponent) => {
        if (PARENT_COMPONENTS_DATA_MAPPING.includes(childComponent.attributes.type)) {
          filteredChildComponents.push(childComponent);
        }
      });
    });
  }
  return filteredChildComponents;
};

const getParentComponentsInView3 = (params) => {
  const filteredChildComponents = [];
  if (window.gpEditor) {
    //add all from the parents
    //filteredChildComponents.push(window.gpEditor.getComponents().filter((op)=> PARENT_COMPONENTS_DATA_MAPPING.includes(op.attributes.type)))
    //add all from the childs
    window.gpEditor.getComponents().forEach((parentComponent) => {
      if (PARENT_COMPONENTS_DATA_MAPPING.includes(parentComponent.attributes.type)) {
        filteredChildComponents.push(parentComponent);
      }
      parentComponent.forEachChild((childComponent) => {
        if (PARENT_COMPONENTS_DATA_MAPPING.includes(childComponent.attributes.type)) {
          filteredChildComponents.push(childComponent);
        }
      });
    });
  }
  return filteredChildComponents;
};

const stopMethodOnClick = (e) => {
  // Call the stop method
  if (e.target.title == "More apps") {
    return false;
  }
  window.gpEditor.Panels.getButton("views", "nine-dots").set("active", false);
  document.body.removeEventListener('click', stopMethodOnClick);
}

export const addCustomPanelTools = (editor, toggleSaveModal, setPublishToProd) => {
  const panelManager = editor.Panels;
  editor.getConfig().showDevices = 0;
  editor.Panels.addPanel({
    id: 'devices', buttons: [
      {
        name: "dsf", id: "set-device-desktop", command: {
          run: function (e) {
            setDeviceAndView("Desktop")
          }, stop() { }
        }, togglable: true, className: "fa fa-desktop", active: true
      },
      {
        name: "dssdf", id: "set-device-mobile", command: {
          run: function (e) {
            setDeviceAndView("Mobile portrait");
          }, stop() { }
        }, togglable: true, className: "fa fa-mobile",
      }
    ]
  });

  function setDeviceAndView(device) {
    editor.setDevice(device);
    //Refresh the view
    const content = JSON.stringify(editor.getComponents());
    editor.setComponents(JSON.parse(content));
  }

  editor.Panels.addButton('options', {
    id: 'custom-panel',
    className: 'fa fa-undo',
    command: 'undo',
    togglable: true,
    attributes: {
      title: 'Undo', // Tooltip text
    },
  });

  editor.Panels.addButton('options', {
    id: 'custom-panel2',
    className: 'fa fa-repeat',
    command: 'redo',
    togglable: true,
    attributes: {
      title: 'Redo', // Tooltip text
    },
  });

  editor.Commands.add("undo", {
    run: (editor) => {
      editor.runCommand("core:undo")
    },
    stop() { }
  });

  editor.Commands.add("redo", {
    run: (editor) => editor.runCommand("core:redo"),
    stop() { }
  });
  editor.Panels.addButton("views", {
    id: "save-menu-management",
    className: 'menumgmt',
    togglable: true,
    command: "save-menu-command",
    attributes: {
      title: "Menu Management",
    },
  });

  editor.Panels.addButton("views", {
    id: "save-global-settings",
    className: "fa fa-sliders",
    togglable: true,
    command: "save-global-command",
    attributes: {
      title: "Global Settings",
    },
  });


  editor.Panels.addButton("views", {
    id: "nine-dots",
    className: "fa fa-th",
    command: "nine-dots",
    togglable: true,
    attributes: {
      title: "More apps",
    },
  });

  let dotsHtml = '';
  fetch("/static/moreapps/links.json").then((raww) => {
    raww.json().then((res) => {

      if (res && res.appLinks) {
        let appLinks = res.appLinks;
        for (let dot of appLinks) {
          if (dot.active) {
            dotsHtml += `<li title="${dot.title}">
              <a href="${dot.isDisabled ? 'javascript:void(0)' : dot.link}" target="#" title="${dot.tooltip}">
                <i>
                  <img src="${dot.icon}">
                </i>
                <p>${dot.title}</p>
              </a>
            </li>`
          }
        }

        var overlay;
        editor.Commands.add("nine-dots", {
          run: () => {
            if (!overlay) {
              overlay = document.createElement('div');
              overlay.innerHTML = `
              
              <div class="more-appsoverlay">
              <ul>
                ${dotsHtml}
              </ul>
              </div>
              `
              const panels = editor.Panels.getPanel('views')
              panels.set('appendContent', overlay).trigger('change:appendContent');
            }
            overlay.style.display = "block";
            document.body.addEventListener('click', stopMethodOnClick);
          },
          stop() {
            if (overlay) {
              overlay.style.display = 'none';
            }
          }
        });

      }

    });
  });


  editor.Panels.addButton("views", {
    id: "logout",
    className: "fa fa-sign-out",
    command: "logout",
    attributes: {
      title: "Logout", // Tooltip text
    },
  });

  editor.Commands.add("logout", {
    run: function (editor) {
      logoutUserSession();
    }.bind(this),
    stop() { }
  });

  editor.Panels.addButton("options", [
    {
      id: "savebtn",
      className: "fa fa-save",
      command: "save-db",
      togglable: false,
      attributes: {
        title: "Save",
      },
    },
  ]);

  // editor.Panels.addButton("options", [
  //   {
  //     id: "save-layout",
  //     className: "fa fa-columns",
  //     command: "save-layout",
  //     togglable: true,
  //     attributes: {
  //       title: "Save Layout",
  //     },
  //   },
  // ]);

  editor.Commands.add("save-db", {
    run: function (editor, sender) {
      let comps = JSON.stringify(editor.getComponents());
      let css = JSON.stringify(getCss(editor));
      persistData(comps, css);
      toggleSaveModal(true);
      setPublishToProd(false);
      editor.Panels.getButton("options", "savebtn").set("attributes", { title: "Already Saved" });
    }.bind(this),
    stop() {
      editor.Panels.getButton("options", "savebtn").set("attributes", { title: "Save" });
    }
  });

  editor.Panels.addButton("options", [
    {
      id: "publishBtn",
      className: "fa fa-upload",
      command: "publish-db",
      togglable: false,
      attributes: {
        title: "Publish",
      },
    },
  ]);

  editor.Commands.add("publish-db", {
    run: function (editor, sender) {
      let comps = JSON.stringify(editor.getComponents());
      let css = JSON.stringify(getCss(editor));

      let defaultIds = localStorage.getItem("defaultIds");
      let isDummyDataPresent = false;
      if (defaultIds && defaultIds.length) {
        isDummyDataPresent = checkForDummyIDs(JSON.parse(comps), JSON.parse(defaultIds));
        if (isDummyDataPresent) {
          showErrorToast("Data mapping for one or more components is missing");
        }
      }

      if (!isDummyDataPresent) {
        persistData(comps, css);
        toggleSaveModal(true);
        setPublishToProd(true);
        editor.Panels.getButton("options", "publishBtn").set("attributes", { title: "Already Published" });
      }
    }.bind(this),
    stop() {
      editor.Panels.getButton("options", "publishBtn").set("attributes", { title: "Publish" });
    }
  });

  // editor.Commands.add("save-layout", {
  //   run: function (editor, sender) {
  //     let comps = JSON.stringify(editor.getComponents());
  //     let css = JSON.stringify(getCss(editor));
  //     persistData(comps, css);
  //     savePageLayout();
  //   },
  //   stop() {} 
  // });

  // Add a custom button with an icon

  editor.on("run:clear-canvas", () => {
    persistData("", "");
  });


  let element = null;
  editor.Commands.add("save-global-command", {
    run: function () {
      if (!element) {
        let globalJson = JSON.parse(getSessionObject('globalJson'));
        let globalCss = JSON.parse(getSessionObject('globalCss'));
        let siteResourceData = getDefaultMsids();
        // let themeData=getThemePagesByWebsite();
        //console.log("site: ",siteResourceData);
        const editMenuDiv = document.createElement('div');
        ReactDOM.render(<GlobalSettingComponent siteResource={siteResourceData} themeJson={globalJson} themeCss={globalCss} />, editMenuDiv);
        const panels = editor.Panels.getPanel('views-container')
        panels && panels.set('appendContent', editMenuDiv).trigger('change:appendContent');
        element = editMenuDiv
      }
      element.style.display = 'block';
    },
    stop: () => {
      if (element) {
        element.style.display = 'none';
        element = null;
      }
    }
  });

  editor.Commands.add("save-menu-command", {
    run: function () {
      if (!element) {
        let globalJson = JSON.parse(getSessionObject('globalJson'));
        let globalCss = JSON.parse(getSessionObject('globalCss'));
        let siteResourceData = getDefaultMsids();
        // let themeData=getThemePagesByWebsite();
        console.log("site: ", siteResourceData);
        const editMenuDiv = document.createElement('div');
        ReactDOM.render(<MenuManagementComponent siteResource={siteResourceData} themeJson={globalJson} themeCss={globalCss} />, editMenuDiv);
        const panels = editor.Panels.getPanel('views-container')
        panels && panels.set('appendContent', editMenuDiv).trigger('change:appendContent');
        element = editMenuDiv
      }
      element.style.display = 'block';
    },
    stop: () => {
      if (element) {
        element.style.display = 'none';
        element = null;
      }
    }
  });

  editor.on("storage:autosave", () => {
    //console.log("save timestamp");
  });
};

