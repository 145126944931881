
/* GRAPESJS Specific stylesheets */
import BreakingNewsGrapesJsStyle from '!!raw-loader!./BreakingNewsGrapeJs.css';
import grid from '!!raw-loader!./grid.css';
import list from '!!raw-loader!./list.module.css';
import footerStyles from '!!raw-loader!./footer.css';
import headerStyles from '!!raw-loader!./header.css';
import MainMenuStyles from '!!raw-loader!./mainMenu.css';
import SideDrawerStyles from '!!raw-loader!./drawerComponent.css';
import BreadCrumbsStyles from '!!raw-loader!./breadCrumbs.css';
import HeroLayoutStyles from '!!raw-loader!./Herolayout.css';
import AuthorShowStyles from '!!raw-loader!./authorShow.css';
import VideoShowStyles from '!!raw-loader!./videoShow.css';
import PageNotFoundStyles from '!!raw-loader!./error.css';
import IndexStyle from '!!raw-loader!../../../src/index.css';
import LayoutStyles from '!!raw-loader!./layout.css';
import articleShowStyles from '!!raw-loader!./articleShow.css';
import SlideShowStyles from '!!raw-loader!./slideShow.css';
import SocialShare from '!!raw-loader!./socialShare.css';
import StaticComponentStyle from '!!raw-loader!./StaticComponent.css';
import WebStoriesStyle from '!!raw-loader!./webstories.css';
import PaginationStyle from '!!raw-loader!./pagination.css';
import SocialShareTwoStyle from '!!raw-loader!./SocialShareTwo.css';
import PaginationTwoStyle from '!!raw-loader!./paginationTwo.css';
import BreakingNewsTwoStyle from '!!raw-loader!./BreakingNewsTwo.css';
import BreakingNewsTickerStyle from '!!raw-loader!./BreakingNewsTicker.css';
import QuickViewTwoStyle from '!!raw-loader!./QuickViewTwo.css';
import ListViewTwoStyle from '!!raw-loader!./ListViewTwo.css';
import HomeGridViewTwoStyle from '!!raw-loader!./HomeGridViewTwo.css';
import WebStoriesTwoStyle from '!!raw-loader!./WebStoriesTwo.css';
import HeroLayoutTwoStyle from '!!raw-loader!./HeroLayoutTwo.css';
import HeroLayoutHrTwoStyle from '!!raw-loader!./HeroLayoutHrTwo.css';
import RhsHomeTwoStyle from '!!raw-loader!./RhsHomeTwo.css';
import RhsHomeOtherTwoStyle from '!!raw-loader!./RhsHomeOtherTwo.css';
import GridViewTwoStyle from '!!raw-loader!./GridViewTwo.css';
import WebStoriesListTwoStyle from '!!raw-loader!./WebStoriesListTwo.css';
import MediaListTwoStyle from '!!raw-loader!./MediaListTwo.css';
import PhotoListTwoStyle from '!!raw-loader!./PhotoListTwo.css';
import ArticleShowTwoStyle from '!!raw-loader!./ArticleShowTwo.css';
import VideoShowTwoStyle from '!!raw-loader!./VideoShowTwo.css';
import SlideShowTwoStyle from '!!raw-loader!./SlideShowTwo.css';
import FooterTwoStyle from '!!raw-loader!./FooterTwo.css';
import HeaderTwoStyle from '!!raw-loader!./HeaderTwo.css';
import MainMenuTwoStyles from '!!raw-loader!./MainMenuTwo.css';
import AuthorShowTwoStyles from '!!raw-loader!./AuthorShowTwo.css';
import StaticPageTwoStyles from '!!raw-loader!./StaticPageTwo.css';
import PageNotFoundTwoStyles from '!!raw-loader!./PageNotFoundTwo.css';
import DrawerComponentTwoStyles from '!!raw-loader!./DrawerComponentTwo.css';
import BreadCrumbsTwoStyles from '!!raw-loader!./BreadCrumbsTwo.css';
import PodcastTwoStyle from '!!raw-loader!./PodcastTwo.css';
/*------ theme three styles ---------*/
import SocialShareThreeStyle from '!!raw-loader!./SocialShareThree.css';
import PaginationThreeStyle from '!!raw-loader!./PaginationThree.css';
import BreakingNewsThreeStyle from '!!raw-loader!./BreakingNewsThree.css';
import BreakingNewsThreeTickerStyle from '!!raw-loader!./BreakingNewsThreeTicker.css';
import QuickViewThreeStyle from '!!raw-loader!./QuickViewThree.css';
import ListViewThreeStyle from '!!raw-loader!./ListViewThree.css';
import HomeGridViewThreeStyle from '!!raw-loader!./HomeGridViewThree.css';
import WebStoriesThreeStyle from '!!raw-loader!./WebStoriesThree.css';
import HeroLayoutThreeStyle from '!!raw-loader!./HeroLayoutThree.css';
import HeroLayoutHrThreeStyle from '!!raw-loader!./HeroLayoutHrThree.css';
import RhsHomeThreeStyle from '!!raw-loader!./RhsHomeThree.css';
import RhsHomeOtherThreeStyle from '!!raw-loader!./RhsHomeOtherThree.css';
import GridViewThreeStyle from '!!raw-loader!./GridViewThree.css';
import WebStoriesListThreeStyle from '!!raw-loader!./WebStoriesListThree.css';
import MediaListThreeStyle from '!!raw-loader!./MediaListThree.css';
import PhotoListThreeStyle from '!!raw-loader!./PhotoListThree.css'; 
import HomePhotoGalleryStyle from '!!raw-loader!./HomePhotoGallery.css'; 
import HomeWebStoriesStyle from '!!raw-loader!./HomeWebStories.css'; 
import ArticleShowThreeStyle from '!!raw-loader!./ArticleShowThree.css';
import QuizShowStyle from '!!raw-loader!./QuizShow.css';
import SurveyShowStyle from '!!raw-loader!./SurveyShow.css';
import VideoShowThreeStyle from '!!raw-loader!./VideoShowThree.css';
import SlideShowThreeStyle from '!!raw-loader!./SlideShowThree.css';
import FooterThreeStyle from '!!raw-loader!./FooterThree.css';
import HeaderThreeStyle from '!!raw-loader!./HeaderThree.css';
import HomeHeaderThreeStyle from '!!raw-loader!./HomeHeaderThree.css';
import HeroLayoutwithHeaderStyles from '!!raw-loader!./HeroLayoutwithHeader.css';
import MainMenuThreeStyles from '!!raw-loader!./MainMenuThree.css';
import AuthorShowThreeStyles from '!!raw-loader!./AuthorShowThree.css';
import StaticPageThreeStyles from '!!raw-loader!./StaticPageThree.css';
import PageNotFoundThreeStyles from '!!raw-loader!./PageNotFoundThree.css';
import DrawerComponentThreeStyles from '!!raw-loader!./DrawerComponentThree.css';
import BreadCrumbsThreeStyles from '!!raw-loader!./BreadCrumbsThree.css';
import PodcastThreeStyle from '!!raw-loader!./PodcastThree.css';
import HomeFaqStyle from '!!raw-loader!./HomeFaq.css';
import FaqListStyle from '!!raw-loader!./FaqList.css';
import FourLinksStyle from '!!raw-loader!./FourLinks.css';
import OtherListViewStyle from '!!raw-loader!./OtherListView.css';
import ExpertsStyle from '!!raw-loader!./Experts.css';
/*------ theme three styles ---------*/
/*------ Amp styles ---------*/
import headerAmpStyles from '!!raw-loader!./headerAmp.css';
import MainMenuAmpStyles from '!!raw-loader!./mainMenuAmp.css';
import articleShowAmpStyles from '!!raw-loader!./articleShowAmp.css';
import SideDrawerAmpStyles from '!!raw-loader!./drawerComponentAmp.css';
import footerAmpStyles from '!!raw-loader!./footerAmp.css';
import BreadCrumbsAmpStyles from '!!raw-loader!./breadCrumbsAmp.css';
import ArticleShowTwoAmpStyles from '!!raw-loader!./ArticleShowTwoAmp.css';
import HeaderTwoAmpStyles from '!!raw-loader!./HeaderTwoAmp.css';
import FooterTwoAmpStyles from '!!raw-loader!./FooterTwoAmp.css';
import BreadCrumbsTwoAmpStyles from '!!raw-loader!./BreadCrumbsTwoAmp.css';
import MainMenuTwoAmpStyles from '!!raw-loader!./MainMenuTwoAmp.css';
import DrawerComponentTwoAmpStyles from '!!raw-loader!./DrawerComponentTwoAmp.css';
import PhotoShowTwoAmpStyles from '!!raw-loader!./PhotoShowTwoAmp.css';
import VideoShowTwoAmpStyles from '!!raw-loader!./VideoShowTwoAmp.css'; 
/*------ Amp styles ---------*/

/*----Theme Three-- Amp styles ---------*/
import ArticleShowThreeAmpStyles from '!!raw-loader!./ArticleShowThreeAmp.css';
import HeaderThreeAmpStyles from '!!raw-loader!./HeaderThreeAmp.css';
import FooterThreeAmpStyles from '!!raw-loader!./FooterThreeAmp.css';
import BreadCrumbsThreeAmpStyles from '!!raw-loader!./BreadCrumbsThreeAmp.css';
import MainMenuThreeAmpStyles from '!!raw-loader!./MainMenuThreeAmp.css';
import DrawerComponentThreeAmpStyles from '!!raw-loader!./DrawerComponentThreeAmp.css';
import PhotoShowThreeAmpStyles from '!!raw-loader!./PhotoShowThreeAmp.css';
import VideoShowThreeAmpStyles from '!!raw-loader!./VideoShowThreeAmp.css'; 
/*----Theme Three-- Amp styles ---------*/


export const STYLES = {
    "BreakingNews": [BreakingNewsGrapesJsStyle, IndexStyle],
    "CarouselCardBlock": [grid],
    "GridView": [grid, PaginationStyle],
    "ListView": [list, footerStyles, PaginationStyle],
    "Header": [headerStyles, MainMenuStyles, SideDrawerStyles, IndexStyle],
    "Footer": [footerStyles],
    "BreadCrumbs": [BreadCrumbsStyles],
    "HeroLayout": [HeroLayoutStyles],
    "AuthorShow": [AuthorShowStyles],
    "VideoShow": [VideoShowStyles, SocialShare],
    "PageNotFound": [PageNotFoundStyles],
    "ArticleShow": [articleShowStyles, SocialShare],
    "SlideShow": [SlideShowStyles, SocialShare],
    "GlobalSettingComponent": [LayoutStyles],
    "GlobalSettingComp": [IndexStyle],
    "GLOBALSTYLES": [IndexStyle],
    "StaticPage":[StaticComponentStyle],
    "WebStories": [WebStoriesStyle],
    "HomeGridViewTwo": [HomeGridViewTwoStyle, IndexStyle],
    "BreakingNewsTwo": [BreakingNewsTwoStyle, IndexStyle, BreakingNewsGrapesJsStyle],
    "BreakingNewsTicker": [BreakingNewsTickerStyle, IndexStyle, BreakingNewsGrapesJsStyle],
    "QuickViewTwo": [QuickViewTwoStyle, IndexStyle, BreakingNewsGrapesJsStyle],
    "WebStoriesTwo":[WebStoriesTwoStyle, IndexStyle, BreakingNewsGrapesJsStyle],
    "HeroLayoutTwo":[HeroLayoutTwoStyle, IndexStyle],
    "HeroLayoutHrTwo":[HeroLayoutHrTwoStyle, IndexStyle],
    "RhsHomeTwo":[RhsHomeTwoStyle, IndexStyle],
    "RhsHomeOtherTwo":[RhsHomeOtherTwoStyle,IndexStyle],
    "GridViewTwo":[GridViewTwoStyle, IndexStyle, PaginationTwoStyle],
    "WebStoriesListTwo":[WebStoriesListTwoStyle, IndexStyle, PaginationTwoStyle],
    "MediaListTwo" : [MediaListTwoStyle, IndexStyle, PaginationTwoStyle],
    "PhotoListTwo" : [PhotoListTwoStyle, IndexStyle, PaginationTwoStyle],
    "ArticleShowTwo":[ArticleShowTwoStyle, IndexStyle, SocialShareTwoStyle],
    "PodcastTwo":[PodcastTwoStyle, IndexStyle, SocialShareTwoStyle],
    "VideoShowTwo":[VideoShowTwoStyle, IndexStyle, SocialShareTwoStyle],
    "SlideShowTwo" : [SlideShowTwoStyle, IndexStyle, SocialShareTwoStyle],
    "FooterTwo":[FooterTwoStyle, IndexStyle],
    "HeaderTwo":[HeaderTwoStyle, MainMenuTwoStyles, DrawerComponentTwoStyles],
    "ListViewTwo": [ListViewTwoStyle, IndexStyle, PaginationTwoStyle],
    "BreadCrumbsTwo": [BreadCrumbsTwoStyles],
    "AuthorShowTwo": [AuthorShowTwoStyles],
    "StaticPageTwo":[StaticPageTwoStyles, PaginationTwoStyle],
    "PageNotFoundTwo":[PageNotFoundTwoStyles],

    /*------ theme three styles ---------*/
    "AuthorShowThree":[AuthorShowThreeStyles,SocialShareThreeStyle,IndexStyle],
    "ArticleShowThree":[ArticleShowThreeStyle,SocialShareThreeStyle,IndexStyle],
    "QuizShow":[QuizShowStyle,SocialShareThreeStyle,IndexStyle],
    "SurveyShow":[SurveyShowStyle,SocialShareThreeStyle,IndexStyle],
    "BreakingNewsThree":[BreakingNewsThreeStyle,IndexStyle],
    "BreakingNewsThreeTicker":[BreakingNewsThreeTickerStyle,IndexStyle],
    "BreadCrumbsThree":[BreadCrumbsThreeStyles,IndexStyle],
    "DrawerComponentThree":[DrawerComponentThreeStyles,IndexStyle],
    "FooterThree":[FooterThreeStyle,SocialShareThreeStyle,IndexStyle],
    "GridViewThree":[GridViewThreeStyle,IndexStyle],
    "HeaderThree":[HeaderThreeStyle,MainMenuThreeStyles,DrawerComponentThreeStyles,IndexStyle],
    "HeroLayoutwithHeader":[HeroLayoutwithHeaderStyles,MainMenuThreeStyles,DrawerComponentThreeStyles,IndexStyle],
    
    "HomeHeaderThree":[HomeHeaderThreeStyle,MainMenuThreeStyles,DrawerComponentThreeStyles, IndexStyle],
    "HomeGridViewThree":[HomeGridViewThreeStyle, IndexStyle],
    "HeroLayoutThree":[HeroLayoutThreeStyle, IndexStyle],
    "HeroLayoutHrThree":[HeroLayoutHrThreeStyle, IndexStyle],
    "ListViewThree":[ListViewThreeStyle,PaginationThreeStyle, IndexStyle],
    "MainMenuThree":[MainMenuThreeStyles, IndexStyle],
    "MediaListThree":[MediaListThreeStyle, PaginationThreeStyle,IndexStyle],
    "PaginationThree":[PaginationThreeStyle, IndexStyle],
    "PageNotFoundThree":[PageNotFoundThreeStyles, IndexStyle],
    "PhotoListThree":[PhotoListThreeStyle,PaginationThreeStyle, IndexStyle],
    "HomePhotoGallery":[HomePhotoGalleryStyle, IndexStyle],
    "HomeWebStories":[HomeWebStoriesStyle, IndexStyle],
    "PodcastThree":[PodcastThreeStyle, IndexStyle],
    "RhsHomeThree":[RhsHomeThreeStyle, IndexStyle],
    "RhsHomeOtherThree":[RhsHomeOtherThreeStyle, IndexStyle],
    "QuickViewThree":[QuickViewThreeStyle, IndexStyle],
    "SocialShareThree":[SocialShareThreeStyle, IndexStyle],
    "SlideShowThree":[SlideShowThreeStyle, IndexStyle],
    "StaticPageThree":[StaticPageThreeStyles, IndexStyle],
    "WebStoriesThree":[WebStoriesThreeStyle, IndexStyle],
    "WebStoriesListThree":[WebStoriesListThreeStyle,PaginationThreeStyle, IndexStyle],
    "VideoShowThree":[VideoShowThreeStyle, IndexStyle, SocialShareThreeStyle],
    "FourLinks":[FourLinksStyle, IndexStyle],
    "HomeFaq":[HomeFaqStyle, IndexStyle],
    "FaqList":[FaqListStyle,PaginationThreeStyle, IndexStyle],
    "OtherListView":[OtherListViewStyle, PaginationThreeStyle,IndexStyle],
    "Experts":[ExpertsStyle, PaginationThreeStyle,IndexStyle],
    
    /*------ theme three styles ---------*/

    /*------ amp styles ---------*/

    "HeaderAmp" :[headerAmpStyles, MainMenuAmpStyles, SideDrawerAmpStyles, IndexStyle],
    "FooterAmp": [footerAmpStyles],
    "BreadCrumbsAmp": [BreadCrumbsAmpStyles],
    "ArticleShowAmp": [articleShowAmpStyles, SocialShare],
    "ArticleShowTwoAmp": [ArticleShowTwoAmpStyles, SocialShare],
    "HeaderTwoAmp": [HeaderTwoAmpStyles, SocialShare, MainMenuTwoAmpStyles, DrawerComponentTwoAmpStyles, IndexStyle],
    "FooterTwoAmp": [FooterTwoAmpStyles, SocialShare],
    "BreadCrumbsTwoAmp":[BreadCrumbsTwoAmpStyles,SocialShare],
    "PhotoShowTwoAmp" : [PhotoShowTwoAmpStyles,SocialShareTwoStyle],
    "VideoShowTwoAmp" : [VideoShowTwoAmpStyles,SocialShareTwoStyle],
    // "MainMenuTwoAmp":[MainMenuTwoAmpStyles,SocialShare],
    // "DrawerComponentTwoAmp":[DrawerComponentTwoAmpStyles,SocialShare]
    /*----Theme Three-- Amp styles ---------*/
    "ArticleShowThreeAmp":[ArticleShowThreeAmpStyles,IndexStyle, SocialShare],
    "HeaderThreeAmp":[HeaderThreeAmpStyles, IndexStyle],
    "FooterThreeAmp":[FooterThreeAmpStyles, IndexStyle],
    "BreadCrumbsThreeAmp":[BreadCrumbsThreeAmpStyles, IndexStyle],
    "MainMenuThreeAmp":[MainMenuThreeAmpStyles, IndexStyle],
    "DrawerComponentThreeAmp":[DrawerComponentThreeAmpStyles, IndexStyle],
    "PhotoShowThreeAmp":[PhotoShowThreeAmpStyles, IndexStyle],
    "VideoShowThreeAmp":[VideoShowThreeAmpStyles, IndexStyle],
    /*----Theme Three-- Amp styles ---------*/
    /*------ amp styles ---------*/

}
